import { AjaxRequest } from 'rxjs/ajax';
import { externalLoggingApi } from '@Utils/externalLoggingFailure/externalLoggingApi';

export const externalLoggingOnError = (
	ajaxConfig: AjaxRequest,
	error: { response: any; xhr?: any; message: any; request: any }
) => {
	const externalLoggingRequest = ajaxConfig.url?.includes('external-logging-service');

	if (!externalLoggingRequest) {
		externalLoggingApi({
			functionalFlow: ajaxConfig.url,
			warningText: JSON.stringify(error?.message),
			errorCode: error?.response?.code || 0,
			request: JSON.stringify(error.request),
			response: JSON.stringify(error.response),
		});
	}
};

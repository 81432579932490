import TextField from '@Components/controls/TextField/TextField';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { AuthenticationService } from '@Services/authentication/authenticationService';
import externalLinks from '@Utils/externalLinks/externalLinks';
import useScreen from '@Utils/hooks/useScreen';
import useTranslate from '@Utils/hooks/useTranslate';
import signInValidator from '@Utils/validators/signInValidator';
import { Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { Col, Visible } from 'react-grid-system';
import { useDispatch } from 'react-redux';
import {
	ButtonsContainer,
	LeftSectionWrapper,
	RocketShadowWrapper,
	RocketWrapper,
	RightContentWrapper,
	LabelWrapper,
	CreateAccountLabelWrapper,
	ForgotButtonContainer,
	IconWrapper,
	MegaMenuCustomButton,
	ButtonWrapper,
	CheckyourEmailWrapper,
	ButtonIconWrapper,
} from '../MegaMenuMyOmantel.styled';
import Image from '@Components/Image/Image';
import { CaptionBigger, ParagraphL, ParagraphM } from '@Components/Typography/Typography.styled';
import Icon from '@Components/Icon/Icon';
import { isRTL } from '@Utils/layout/layoutSupport';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';

interface IFormikFields {
	login: string;
	password: string;
}
interface INonLoggedContentProps {
	forceClose: () => void;
}

const LOGIN_BI = 'userLogIn';

const NonLoggedContent: FunctionComponent<INonLoggedContentProps> = ({ forceClose }) => {
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const { screen } = useScreen();
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const handleClickLink = (path: string) => {
		window.location.href = path;
	};

	const handleSubmit = ({
		values: { login, password },
		setSubmitting,
		setErrors,
		forceClose,
	}: {
		values: IFormikFields;
		setSubmitting: FormikHelpers<IFormikFields>['setSubmitting'];
		setErrors: FormikHelpers<IFormikFields>['setErrors'];
		forceClose: () => void;
	}) => {
		setSubmitting(true);
		dispatch(addBusyIndicator(LOGIN_BI));
		AuthenticationService.login(login, password, false, forceClose).subscribe(
			() => {
				dispatch(removeBusyIndicator(LOGIN_BI));
				setSubmitting(false);
			},
			() => {
				dispatch(removeBusyIndicator(LOGIN_BI));
				setSubmitting(false);
				setErrors({ password: translate('modal.login.invalid.credentials') });
			}
		);
	};

	return (
		<LeftSectionWrapper>
			<Col md={6} xl={6} lg={6} sm={6}>
				<Formik<IFormikFields>
					initialValues={{ login: '', password: '' }}
					onSubmit={(values, { setSubmitting, setErrors }) => {
						handleSubmit({ values, setSubmitting, setErrors, forceClose });
					}}
					validationSchema={signInValidator()}
				>
					{({ values: { login, password }, setFieldValue, errors, touched, handleSubmit }) => (
						<form>
							<LabelWrapper>
								<CaptionBigger color="dark64">{translate('modal.login.login-label')}</CaptionBigger>
							</LabelWrapper>
							<TextField
								id="0"
								typography="paragraphM"
								placeholderTypography="paragraphM"
								placeholderColor="dark57"
								placeholder={translate('modal.login.phone.number.or.email')}
								value={login}
								onChange={(value) => {
									setFieldValue('login', value);
								}}
								marginBottom={24}
								message={!!errors.login ? errors.login : ''}
								error={!!errors.login}
								width={screen(['xl', 'lg']) ? 360 : '100%'}
								padding="12px"
								onEnter={handleSubmit}
								height={48}
								borderColor="theme300"
								inActive={true}
								boxShadow={true}
								noBorder={true}
							/>
							<LabelWrapper>
								<CaptionBigger color="dark64">{translate('modal.login.password')}</CaptionBigger>
							</LabelWrapper>
							<TextField
								id="0"
								typography="paragraphM"
								placeholderTypography="paragraphM"
								placeholder={translate('modal.login.password')}
								placeholderColor="dark57"
								value={password}
								onEnter={handleSubmit}
								onChange={(value) => {
									setFieldValue('password', value);
								}}
								marginBottom={24}
								message={!!errors.password && touched.password ? errors.password : ''}
								error={!!errors.password && touched.password}
								type={showPassword ? 'text' : 'password'}
								width={screen(['xl', 'lg']) ? 360 : '100%'}
								height={48}
								padding="12px"
								rightIcon={showPassword ? 'eyeClosed' : 'eyeOpen'}
								onIconClick={() => setShowPassword(!showPassword)}
								borderColor="theme300"
								inActive={true}
								boxShadow={true}
								noBorder={true}
							/>
							<ButtonsContainer isLG={screen(['xl', 'lg'])} isLGMD={screen(['xl', 'lg', 'md'])}>
								<ButtonWrapper>
									<MegaMenuCustomButton
										id="megamenu.my-omantel.forgot-password.button.btn"
										onClick={() => handleClickLink(externalLinks('FORGOT_PASSWORD'))}
										backgroundColor="transparent"
										customWidth={screen(['xl']) ? '168px' : undefined}
										padding="0px"
										height="48px"
										justifyContent={
											screen(['xl']) ? 'center' : screen(['sm', 'xs']) ? 'center' : 'flex-start'
										}
									>
										<ForgotButtonContainer>
											<ConditionalRender
												show={isRTL()}
												onTrue={() => (
													<IconWrapper>
														<Icon
															name="arrowRightBox"
															fill="primary"
															height={16}
															width={16}
														/>
													</IconWrapper>
												)}
											/>
											<ParagraphM color="primary">
												{translate('megamenu.my-omantel.forgot-password.button')}
											</ParagraphM>
											<ConditionalRender
												show={!isRTL()}
												onTrue={() => (
													<IconWrapper>
														<Icon
															name="arrowRightBox"
															fill="primary"
															height={16}
															width={16}
														/>
													</IconWrapper>
												)}
											/>
										</ForgotButtonContainer>
									</MegaMenuCustomButton>
								</ButtonWrapper>

								<ButtonWrapper>
									<BusyIndicator listener={[LOGIN_BI]} skeleton="smallCircularLoader">
										<MegaMenuCustomButton
											onClick={() => {
												handleSubmit();
											}}
											backgroundColor="primary"
											primaryBackground={true}
											customWidth={screen(['xl']) ? '168px' : undefined}
											padding="0px"
											height="48px"
										>
											<ParagraphM color="white">
												{translate('megamenu.my-omantel.log-in.button')}
											</ParagraphM>
										</MegaMenuCustomButton>
									</BusyIndicator>
								</ButtonWrapper>
							</ButtonsContainer>
						</form>
					)}
				</Formik>
			</Col>
			<Col md={6} xl={6} lg={6} sm={6}>
				<RightContentWrapper
					isxl={screen(['xl', 'lg'])}
					isMd={screen(['md'])}
					isSm={screen(['sm'])}
					isXs={screen(['xs'])}
				>
					<CreateAccountLabelWrapper>
						<ParagraphL>{translate('megamenu.my-omantel.create-account.title')}</ParagraphL>
					</CreateAccountLabelWrapper>
					<MegaMenuCustomButton
						secondary={true}
						onClick={() => handleClickLink(externalLinks('REGISTER'))}
						customWidth={screen(['xs']) ? '93%' : isRTL() ? '191.188px' : '145.938px'}
					>
						<ParagraphM color="primary">
							{translate('megamenu.my-omantel.create-account.button')}
						</ParagraphM>
					</MegaMenuCustomButton>
					<CheckyourEmailWrapper>
						<MegaMenuCustomButton
							onClick={() => handleClickLink(`https://mail.omantel.net.om`)}
							customWidth={screen(['xs']) ? '93%' : isRTL() ? '191.188px' : '145.938px'}
							backgroundColor="transparent"
						>
							<ForgotButtonContainer>
								<ButtonIconWrapper>
									<Icon name="sms" height={16} width={16} />
								</ButtonIconWrapper>
								<ParagraphM color="primary">{translate('megamenu.my-omantel.check-email')}</ParagraphM>
							</ForgotButtonContainer>
						</MegaMenuCustomButton>
					</CheckyourEmailWrapper>
				</RightContentWrapper>
			</Col>
			<Visible xl lg>
				<RocketWrapper>
					<Image src="rocket.png" height={199.74} width={94.52}></Image>
				</RocketWrapper>
				<RocketShadowWrapper>
					<Image src="rocket_shadow.png" height={100.06} width={291.44}></Image>
				</RocketShadowWrapper>
			</Visible>
		</LeftSectionWrapper>
	);
};

export default NonLoggedContent;

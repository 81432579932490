import React, { FunctionComponent, useState } from 'react';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { Col, Row } from 'react-grid-system';
import {
	BGContainer,
	Button,
	CardContainer,
	CardWrapper,
	CreateAccount,
	Footer,
	LogoWrapper,
	TextBodyM,
	TextButton,
	CloseIconWrapper,
	Header,
} from './Login.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
import { BodyM, TitleBold } from '@Components/Typography/Typography';
import TextField from '@Components/controls/TextField/TextField';
import { PrimaryLargeButtonFixed, SecondaryLargeButtonFixed } from '@Components/controls/Button/Button';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { useDispatch } from 'react-redux';
import { setModal } from '@Redux/modules/modal/actions';
import { signIn } from '@Redux/modules/api/authentication/actions';
import { updateHeader } from '@Redux/modules/api/headers/actions';
import { AuthenticationService } from '@Services/authentication/authenticationService';
import Divider from '@Components/Divider/Divider';
import externalLinks from '@Utils/externalLinks/externalLinks';
import useScreen from '@Utils/hooks/useScreen';

const Login: FunctionComponent<types.cards.ILoginCardProps> = ({
	params: { onContinue, onAbort, withoutFooter },
}): JSX.Element => {
	const [login, setLogin] = useState<string | number>('');
	const [password, setPassword] = useState<string | number>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');

	const { translate } = useTranslate();
	const dispatch = useDispatch();

	const { screen } = useScreen();
	const isXS = screen('xs');
	const isSM = screen('sm');

	const handleClose = () => {
		onAbort?.();
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	const _signIn = () => {
		setLoading(true);
		setIsError(false);
		setErrorMsg('');
		AuthenticationService.login(login.toString(), password.toString()).subscribe(
			() => {
				setLoading(false);
				onContinue?.();
			},
			() => {
				setIsError(true);
				setLoading(false);
				setErrorMsg(translate('modal.login.invalid.credentials'));
			}
		);
	};

	const forgotPassword = () => {
		window.open(externalLinks('FORGOT_PASSWORD'));
	};

	const createAccount = () => {
		window.open(externalLinks('REGISTER'));
	};

	const continueAsGuest = () => {
		OrderFlowService.guestLogIn().subscribe(
			(r) => {
				dispatch(
					updateHeader({
						name: 'WCTrustedToken',
						value: r.WCTrustedToken,
					})
				);
				dispatch(
					updateHeader({
						name: 'WCToken',
						value: r.WCToken,
					})
				);
				dispatch(
					updateHeader({
						name: 'WCPersonalization',
						value: r.personalizationID,
					})
				);
				dispatch(
					signIn({
						signedIn: false,
						telesales: false,
						loginType: 'guest',
					})
				);
				dispatch(
					setModal({
						type: null,
						data: null,
					})
				);
				onContinue?.();
			},
			() => {}
		);
	};

	return (
		<CardContainer>
			<BGContainer>
				<Icon name="loginBg" width={460} height={520} />
			</BGContainer>
			<CardWrapper isXS={isXS}>
				<Row>
					<Header>
						<LogoWrapper>
							<Icon name="omantelLogo" width={96} height={48} />
						</LogoWrapper>
						<CloseIconWrapper onClick={handleClose}>
							<Icon name="close" fill="black" width={24} height={24} />
						</CloseIconWrapper>
					</Header>
				</Row>
				<Row>
					<Divider marginBottom={40} withoutLine />
				</Row>
				<ConditionalRender
					show={!!withoutFooter && !isXS}
					onTrue={() => (
						<Row>
							<Col>
								<>
									<BodyM color="black54">{translate('car-insurance.login.prompt')}</BodyM>
									<Divider marginBottom={8} withoutLine />
								</>
							</Col>
							<Divider marginBottom={40} withoutLine />
						</Row>
					)}
				/>
				<Row>
					<Col>
						<TitleBold>{translate('modal.login.returning.customers')}</TitleBold>
						<Divider marginBottom={8} withoutLine />
						<TextField
							id="login"
							name="login"
							type="text"
							placeholder={translate('modal.login.phone.number.or.email')}
							value={login}
							onChange={(login) => {
								setLogin(login);
							}}
							marginBottom={8}
							error={isError}
							onEnter={() => _signIn()}
						/>
						<TextField
							id="1"
							name="password"
							placeholder={translate('modal.login.password')}
							value={password}
							onChange={(password) => {
								setPassword(password);
							}}
							marginBottom={8}
							message={errorMsg}
							error={isError}
							type="password"
							onEnter={() => _signIn()}
						/>
						<Divider marginBottom={8} withoutLine />
						<Button isXS={isXS}>
							<PrimaryLargeButtonFixed
								uniqueId="modal.login.sign.in"
								listener={loading}
								onClick={() => _signIn()}
							>
								{translate('modal.login.sign.in')}
							</PrimaryLargeButtonFixed>
						</Button>
						<Divider marginBottom={16} withoutLine />
						<TextButton onClick={() => forgotPassword()}>
							{translate('modal.login.forgot.password')}
						</TextButton>
						<Divider marginBottom={40} withoutLine />
					</Col>
					<Col>
						<TitleBold>{translate('modal.login.dont.have.account')}</TitleBold>
						<Divider marginBottom={8} withoutLine />
						<BodyM color="black54">{translate('modal.login.omantel.client.already')}</BodyM>
						<Divider marginBottom={16} withoutLine />
						<CreateAccount>
							<TextBodyM>{translate('modal.login.create.account-pre')}</TextBodyM>
							<TextButton onClick={() => createAccount()}>
								{translate('modal.login.create.account')}
							</TextButton>
							<TextBodyM>{translate('modal.login.create.account.shipping.easy')}</TextBodyM>
						</CreateAccount>
					</Col>
				</Row>
			</CardWrapper>
			<ConditionalRender
				show={!withoutFooter}
				onTrue={() => (
					<Footer>
						<Row>
							<Col xs={12} sm={12} md={6} lg={6}>
								<Divider marginBottom={16} withoutLine />
								<TitleBold>{translate('modal.login.not.omantel.client')}</TitleBold>
							</Col>
							<Col xs={12} sm={12} md={6} lg={6}>
								<Button isXS={isXS}>
									<ConditionalRender
										show={isXS || isSM}
										onTrue={() => <Divider withoutLine marginBottom={16} />}
									/>
									<SecondaryLargeButtonFixed
										uniqueId="modal.continue.as.guest"
										onClick={() => continueAsGuest()}
									>
										{translate('modal.login.continue.as.guest')}
									</SecondaryLargeButtonFixed>
								</Button>
							</Col>
						</Row>
					</Footer>
				)}
			/>
		</CardContainer>
	);
};

export default Login;

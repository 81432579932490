import { setSelectedItems, clearSelectedItems, ISelectedItems } from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';

const init = {
	details: undefined,
	plan: undefined,
	currentPlan: undefined,
	activeProvisioning: undefined,
	activePlanCommitment: undefined,
	selectedAccount: undefined,
	inStock: false,
	newPlanIsHigher: undefined,
	planType: 'DEVICE_ONLY',
	showWarning: false,
	profileHasMultiAccount: false,
	isHBB: false,
	isWFBB5G: false,
	isWFBB4G: false,
	wrongDeviceChoosen: false,
	isHbbInstallmentsOffer: false,
	userHaveLegacyPlan: false,
	cantBeUpgradedPlanId: '',
	isNewPCTSmartphones: false,
	isNewPCTAccessory: false,
	isNewPCTLaptopTablet: false,
	newPCTDevice: '',
};

type draft = typeof init;

export interface ISelectedItemsDraft extends draft {}

const reducers = {
	[setSelectedItems.toString()](draft: draft, action: ISelectedItems) {
		return { ...draft, ...action.payload };
	},
	[clearSelectedItems.toString()]() {
		return init;
	},
};

export default immerHandleActions(reducers, init);

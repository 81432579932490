import apiConfig from '../config';
import { ajax, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';
import store, { dispatch } from '@Redux/store';
import textToHash from '@Utils/converters/textToHash';
import { cacheApiRequest, invalidateCache as invalidateCacheAction } from '@Redux/modules/api/cache/actions';
import { isRequestCachedAndNotExpired, isRequestCached } from '@Redux/modules/api/cache/selectors';
import dateAfterTimestamp from '@Utils/date/dateAfterTimestamp';
import models from '@Utils/api/models/index';
import modelsResolver from '@Utils/api/models/utils/modelsResolver';
import errorCodes from '@Utils/api/errors/errorCodes';
import ErrorMapper from '@Utils/api/errors/errorMapper';
import { ApiQuery } from '@Utils/api';
import { INITIAL_HEADERS } from '@Redux/modules/api/headers/reducer';
import { setToast } from '@Redux/modules/toast/actions';
import { getHeaders, getHeadersWithoutTokens } from '@Redux/modules/api/headers/selectors';
import { AuthenticationService } from '@Services/authentication/authenticationService';
import { isUserSignedIn } from '@Redux/modules/api/authentication/selectors';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { deleteWCTokens } from '@Redux/modules/api/headers/actions';
import { getLang } from '@Utils/language/language';
import { ProfileService } from '@Services/profile/profileService';
import { tap } from 'rxjs/operators';
import { setConfig } from '@Redux/modules/settings/config/actions';
import { signIn } from '@Redux/modules/api/authentication/actions';
import { updateHeader } from '@Redux/modules/api/headers/actions';
import { updateCart } from '@Redux/modules/cart/actions';
import { invalidateAllCache } from '@Redux/modules/api/cache/actions';
import { externalLoggingOnError } from './util';

interface IParams {
	endpointConfig: types.endpoints.IEndpointConfig;
	fullURL: types.ajax.url;
	method: types.ajax.method;
	offlineCustomResponse: Record<string, any>;
	handlingOffline: boolean;
	payloadHash: number;
	invalidateKey?: ApiQuery;
}

interface IRequestInProgress extends types.redux.api.requestInProgress.IRequestInProgress {}
interface IErrorInterface {
	errorCode: string;
	errorMessage: string;
}

type additionalHeader =
	| 'X-Watson-Metadata'
	| 'X-Omt-User-Language'
	| 'X-Omt-App'
	| 'X-Omt-Device'
	| 'x-device-id'
	| 'x-unique-id'
	| 'x-channel-id'
	| 'x-language';

const SESSION_ERROR_CODES = ['CMN1039E', 'CWXBB1103E', 'CWXFR0223E'];

export default class RxQuery {
	private get params(): IParams {
		const endpointConfig = this.endpointConfig || {
			method: 'GET',
			url: '',
		};
		const fullURL = endpointConfig ? String(endpointConfig.url) : '';
		const method = endpointConfig && endpointConfig.method ? endpointConfig.method : 'GET';

		return {
			endpointConfig,
			fullURL,
			handlingOffline: this.handlingOffline,
			invalidateKey: this.invalidateKey,
			method,
			offlineCustomResponse: this.offlineCustomResponse,
			payloadHash: this.payloadHash,
		};
	}

	public readonly source: string;
	private endpointConfig?: types.endpoints.IEndpointConfig;
	private payloadObject?: Record<string, unknown> | null;
	private payloadHash: number = 0;
	private cacheTime: number = apiConfig.defaultCacheTime;
	private errorMsg?: string = 'Connecting error';
	private offlineCustomResponse: any;
	private handlingOffline: boolean = false;
	private cache: boolean = true;
	private model?: keyof typeof models = undefined;
	private responseType?: XMLHttpRequestResponseType;
	private additionalHeaders: { [key in additionalHeader]?: string } = {};
	private errorHandling: boolean = false;
	private formReference: Record<string, unknown> | null = null;
	private invalidateKey?: ApiQuery;
	private logoutOnError: boolean = false;
	private timeout: number = apiConfig.timeout;
	private withoutTokenHeader: boolean = false;
	private withCredentials: boolean = true;
	private omitContentTypeHeader: boolean = false;

	constructor(source: string) {
		this.source = source;
	}

	public getParams(): IParams {
		return this.params;
	}

	public withoutTokens(): this {
		this.withoutTokenHeader = true;
		return this;
	}

	public disabledWithCredentials = (): this => {
		this.withCredentials = false;
		return this;
	};

	public setEndpoint(endpointConfig: types.endpoints.IEndpointConfig): this {
		this.endpointConfig = endpointConfig;
		return this;
	}

	/**
	 * @description Function to disable `Content-Type` header for specific requests
	 * @returns {RxQuery}
	 */
	public disabledContentTypeHeader(): this {
		this.omitContentTypeHeader = true;
		return this;
	}

	public setPayload<T = unknown>(payloadObject: Record<string, T> | null): this {
		this.payloadObject = payloadObject;
		this.payloadHash = textToHash(JSON.stringify(payloadObject));
		return this;
	}

	public setCacheTime(cacheTime?: number): this {
		this.cacheTime = cacheTime || apiConfig.defaultCacheTime;
		return this;
	}

	public disableCache(): this {
		this.cache = false;
		return this;
	}

	public setErrorMsg(errorMsg: string): this {
		this.errorMsg = errorMsg;
		return this;
	}

	public handleOffline(offlineCustomResponse?: Record<string, any>): this {
		this.cache = true;
		this.handlingOffline = true;
		this.offlineCustomResponse = offlineCustomResponse;
		return this;
	}

	public forceCache(): this {
		this.cache = true;
		return this;
	}

	public withErrorHandling(
		ref: Record<string, unknown> | null = null,
		handleError: boolean | undefined = true
	): this {
		this.errorHandling = handleError;
		this.formReference = ref;
		return this;
	}

	// DEBUG
	public print(): string {
		return JSON.stringify(
			{
				params: this.params,
				payloadObject: this.payloadObject,
			},
			null,
			2
		);
	}

	public unsetType(): this {
		this.model = undefined;
		return this;
	}

	public setType(modelName: keyof typeof models): this {
		this.model = modelName;
		return this;
	}

	public setResponseType(type: XMLHttpRequestResponseType): this {
		this.responseType = type;
		return this;
	}

	public setHeader(name: additionalHeader, value: string): this {
		this.additionalHeaders[name] = value;
		return this;
	}

	public setHeaders(object: { [key in additionalHeader]?: string }): this {
		this.additionalHeaders = {
			...this.additionalHeaders,
			...object,
		};
		return this;
	}

	public invalidateCache = (forAllUsers = false): void => {
		const {
			endpointConfig: { url: endpointUrl },
			payloadHash,
		} = this.params;

		dispatch(
			invalidateCacheAction({
				endpointUrl,
				payloadHash: forAllUsers ? undefined : payloadHash,
			})
		);
	};

	public setInvalidateKey = (key: ApiQuery | string): this => {
		this.invalidateKey = key;
		return this;
	};

	public setLogoutOnError = (): this => {
		this.logoutOnError = true;
		return this;
	};

	public setTimeout = (milliseconds: number): this => {
		this.timeout = milliseconds;
		return this;
	};

	public call(): Observable<any> {
		const { endpointConfig, handlingOffline, offlineCustomResponse, fullURL } = this.params;
		if (this.cache && this.invalidateKey === undefined) {
			this.setInvalidateKey(fullURL);
		}
		let responseFromCache: any;

		if (handlingOffline && offlineCustomResponse) {
			responseFromCache = {
				response: {
					response: offlineCustomResponse,
				},
			};
		} else {
			const validationMethod = handlingOffline ? isRequestCached : isRequestCachedAndNotExpired;
			responseFromCache = validationMethod({
				endpointUrl: endpointConfig.url,
				payloadHash: this.payloadHash,
			});
		}

		if (responseFromCache) {
			return this.onCallFromCache(responseFromCache.response);
		} else {
			return this.onCall();
		}
	}

	public forceCall(): Observable<any> {
		return this.onCall();
	}

	private newRequestInProgress(rxAjax$?: Observable<AjaxResponse>): IRequestInProgress {
		return { url: this.params.fullURL, payloadHash: this.payloadHash, rxAjax$ };
	}

	private cacheRequestIfNeeded(response: any, force = false): void {
		const { endpointConfig, invalidateKey } = this.params;
		const validTill = dateAfterTimestamp(this.cacheTime);
		if (this.cache || force) {
			dispatch(
				cacheApiRequest({
					endpointUrl: endpointConfig.url,
					invalidateKey,
					payloadHash: this.payloadHash,
					response,
					validTill,
				})
			);
		}
	}

	private asModel = (response: AjaxResponse) => {
		if (this.model && response.response) {
			return modelsResolver(response, this.model);
		} else {
			return response;
		}
	};

	private handleErrors(error: { response: any; xhr?: any; message: any; request: any }, ajaxConfig: AjaxRequest) {
		error.response = error.response || { code: 'ERROR_TIMEOUT' };
		const mappedError = ErrorMapper.mapToMessage(error.response.code);

		const translatedError: IErrorInterface = {
			errorCode: error.response.code,
			errorMessage: mappedError,
		};
		const JSESSIONIDExpired = ((error.response?.['Error message'] as string) ?? '').includes('Session');
		const errorKey = error.response?.errors?.[0]?.errorKey;

		const errorKeys = ['ERR_COOKIE_EXPIRED', '_ERR_INVALID_COOKIE'];
		const errorKeysInvalidParam = [
			'_ERR_CMD_INVALID_PARAM',
			'_ERR_MISSING_PARMS',
			'UNAUTHORIZED',
			'AUTHENTICATION_DATA_WRONG',
		];

		const externalLoggingEnabled =
			store?.getState()?.settings?.remoteConfig?.sidebar?.EXTERNAL_LOGGING_OMNTL_3300 ?? true;
		if (externalLoggingEnabled) {
			externalLoggingOnError(ajaxConfig, error);
		}

		if (errorKeys.includes(errorKey)) {
			dispatch(deleteWCTokens());
			return ProfileService.getOmantelIdentity().pipe(
				tap((r) => {
					dispatch(invalidateAllCache());
					dispatch(setConfig({ accountCategory: r.supportedAccountCategories }));
					dispatch(
						signIn({
							telemarketer: r.telesales,
						})
					);
					dispatch(
						updateHeader({
							name: 'WCTrustedToken',
							value: r.WCTrustedToken,
						})
					);
					dispatch(
						updateHeader({
							name: 'WCToken',
							value: r.WCToken,
						})
					);
					dispatch(
						updateHeader({
							name: 'WCPersonalization',
							value: r.personalizationID,
						})
					);
					dispatch(updateCart(true));
				})
			);
		} else if (JSESSIONIDExpired || errorKeysInvalidParam.includes(errorKey)) {
			dispatch(deleteWCTokens());
			const signedIn = isUserSignedIn();

			const openModal = () => {
				dispatch(setModal({ closeAllModals: true, type: null }));
				dispatch(
					setModal({
						type: modalTypes.LOGIN_SESSION_EXPIRED,
						actionOnClose: () => {
							if (location.pathname.includes('account')) {
								location.href = `/${getLang()}/store`;
							}
							location.reload();
						},
					})
				);
			};
			AuthenticationService.logout().subscribe(
				() => {
					if (signedIn) {
						openModal();
					}
				},
				() => {
					if (signedIn) {
						openModal();
					}
				}
			);
		}

		if (this.errorHandling) {
			const errorCodeFromMap = errorCodes.get(translatedError.errorCode);
			if (errorCodeFromMap) {
				const { generalError, formField } = errorCodeFromMap;
				if (generalError) {
					dispatch(setToast({ error: true, label: translatedError.errorMessage }));
				} else {
					if (this.formReference !== null) {
						const {
							setFieldError,
							initialValues,
						}: {
							setFieldError: (formField: string[], message: string) => void;
							initialValues: Record<string, any>;
						} = this.formReference as any;
						const formRefFieldNames = Object.keys(initialValues);
						const fieldExist = formRefFieldNames.find((fieldName) => formField.includes(fieldName));
						fieldExist
							? setFieldError(formField, translatedError.errorMessage)
							: dispatch(
									setToast({
										error: true,
										label: ErrorMapper.mapToMessage(translatedError.errorCode),
									})
							  );
					}
				}
			} else {
				dispatch(
					setToast({
						error: true,
						label: ErrorMapper.mapToMessage(error.response.code),
					})
				);
			}
		}
	}

	// final rxjs call
	private onCall(): Observable<AjaxResponse> {
		const { fullURL, method } = this.params;
		const ajaxConfig = {
			body: (method === 'POST' || method === 'PUT') && this.payloadObject ? this.payloadObject : undefined,
			headers: INITIAL_HEADERS,
			method,
			redirect: 'follow',
			responseType: this.responseType || 'json',
			timeout: this.timeout,
			url: fullURL,
			withCredentials: this.withCredentials,
		};

		try {
			ajaxConfig.headers = {
				...(this.withoutTokenHeader ? getHeadersWithoutTokens() : getHeaders()),
				...this.additionalHeaders,
				...(this.cache ? {} : { 'Cache-Control': 'no-cache' }),
			};

			/**
			 * @description Omit the `Content-Type` header from the request
			 * to avoid potential security vulnerabilities
			 * for ref. clickup id: https://app.clickup.com/t/31133681/ODF-8026
			 */
			if (this.omitContentTypeHeader) {
				delete ajaxConfig.headers['Content-Type'];
			}
		} catch {}

		const rxAjax$ = ajax(ajaxConfig).pipe(
			map((response) => {
				this.cacheRequestIfNeeded(response);
				return this.asModel(response);
			}),
			catchError((error) => {
				this.handleErrors(error, ajaxConfig);
				return throwError(error);
			})
		);
		return rxAjax$;
	}

	private onCallFromCache(response: any) {
		return of(response).pipe(
			map(
				(response) => {
					return this.asModel(response);
				},
				(e: Error) => console.log(this.errorMsg, e)
			),
			catchError((error) => {
				return throwError(error);
			})
		);
	}
}

import { BodyM } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';

interface ISmallScreen {
	isXs?: boolean;
	isSm?: boolean;
}

export const Container = styled.div<ISmallScreen>`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 5;
	${({ isXs, isSm }) => {
		return {
			width: !isXs && !isSm ? 600 : isXs ? '95%' : '80%',
		};
	}}
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	padding: 20px;
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
	})};
`;

export const LabelWrapper = styled(BodyM)<ISmallScreen>`
	display: flex;
	flex-direction: row;
	align-items: center;
	opacity: 0.8;
	${({ theme, isXs }) => {
		return {
			color: theme.colors.black87,
			margin: isXs ? '0px 0px 32px 0px' : '0px 0px 24px 0',
		};
	}}
	p {
		display: inline;
	}
`;

export const ButtonsWrapper = styled.div<ISmallScreen>`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	${({ isXs }) => {
		return {
			flexDirection: isXs ? 'column' : 'row',
		};
	}}
	align-items: center;
`;

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
	justify-content: flex-end;
`;

export const CloseIconWrapper = styled.div`
	cursor: pointer;
`;

import { IUsage } from '@ApiModels/usage';
import { createAction } from 'redux-actions';
import { CreditRating } from '@Config/app.config';

export interface IPersonalData {
	bills?: string;
	usage?: IUsage;
	usageData?: string;
	makasibPoints?: string;
	expiryMakasibPoints?: string;
	nationality?: string;
	creditRating?: CreditRating;
	coRelationId?: string;
}

export const setPersonalData = createAction('SET_PERSONAL_DATA', (action: IPersonalData) => action);

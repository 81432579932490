import { AccountsService } from '@Services/accounts/accountsService';

export const externalLoggingApi = ({
	functionalFlow,
	warningText,
	errorCode,
	response,
	request,
}: {
	functionalFlow?: string;
	warningText: string;
	errorCode: string;
	response?: string;
	request?: string | undefined;
}): void => {
	AccountsService.externalLoggingService(
		functionalFlow ?? '',
		errorCode,
		warningText,
		response ? response : '',
		request
	).subscribe(() => {});
};

import React, { FunctionComponent } from 'react';
import {
	ButtonsWrapper,
	Container,
	Wrapper,
	LabelWrapper,
	CloseIconWrapper,
	TitleWrapper,
} from './WeakPassword.styled';
import { setModal } from '@Redux/modules/modal/actions';
import { useDispatch } from 'react-redux';
import useTranslate from '@Utils/hooks/useTranslate';
import { PrimaryMediumButtonFlex } from '@Components/controls/Button/Button';
import useScreen from '@Utils/hooks/useScreen';
import externalLinks from '@Utils/externalLinks/externalLinks';
import Icon from '@Components/Icon/Icon';

const WeakPassword: FunctionComponent = () => {
	const dispatch = useDispatch();
	const { translate } = useTranslate();
	const { screen } = useScreen();

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	const resetPassword = () => {
		handleClose();
		window.location.href = externalLinks('FORGOT_PASSWORD');
	};

	return (
		<Container isXs={screen('xs')} isSm={screen('sm')}>
			<Wrapper>
				<TitleWrapper>
					<CloseIconWrapper onClick={handleClose}>
						<Icon name="close" fill="black" width={24} height={24} />
					</CloseIconWrapper>
				</TitleWrapper>
				<LabelWrapper isXs={screen('xs')}>{translate('modal.weak-password.message')}</LabelWrapper>

				<ButtonsWrapper isXs={screen('xs')}>
					<PrimaryMediumButtonFlex
						uniqueId="modal.weak-password.reset-password"
						onClick={() => resetPassword()}
					>
						{translate('modal.weak-password.reset-password')}
					</PrimaryMediumButtonFlex>
				</ButtonsWrapper>
			</Wrapper>
		</Container>
	);
};

export default WeakPassword;

import { Action, ActionFunction1, createAction } from 'redux-actions';

export enum modalTypes {
	NOTIFY_AVAILABILITY = 'notifyAvailability',
	PRODUCT_AVAILABILITY = 'productAvailability',
	PLAN_SELECTOR = 'planSelector',
	GENERIC_ERROR = 'genericError',
	LOGIN = 'login',
	LOGIN_SESSION_EXPIRED = 'loginSessionExpired',
	IMAGE_PREVIEW = 'imagePreview',
	DELIVERY_ADDRESS = 'deliveryAddress',
	DELETE_PAYMENT_TOKEN = 'deletePaymentToken',
	SHOW_ON_MAP = 'showOnMap',
	ADDED_TO_CART = 'addedToCart',
	PLAN_DETAILS = 'planDetails',
	UPSELL = 'upsell',
	GIFT_CARD_CODES = 'giftCardCodes',
	SELECT_NUMBER = 'selectNumber',
	ADDITIONAL_PRODUCT = 'additionalProduct',
	TELESALES_OTP = 'telesalesOtp',
	STATUS = 'status',
	TERMS_AND_CONDITIONS = 'termsAndConditions',
	OTP = 'OTP',
	OUTLET_AVAILABLE = 'outletAvailable',
	PICKUP_BOOKING = 'pickupBooking',
	CANCEL_ORDER = 'cancelOrder',
	MAKASIB_WARNING = 'makasibWarning',
	WEAK_PASSWORD = 'weakPassword',
	MICROSOFT_CARD_DETAILS = 'microsoftCardDetails',
}

export interface ISetModalAction {
	payload: {
		data?: any;
		type?: modalTypes | null;
		availableClosing?: boolean;
		closeAllModals?: boolean;
		actionOnClose?: () => void;
		withoutContainer?: boolean;
	};
}

interface ISetModalStateAction {
	payload: any;
}

export const setModal: ActionFunction1<ISetModalAction['payload'], Action<ISetModalAction['payload']>> = createAction(
	'SET_MODAL',
	(action: ISetModalAction['payload']) => action
);

export const setModalState: ActionFunction1<
	ISetModalStateAction['payload'],
	Action<ISetModalStateAction['payload']>
> = createAction('SET_MODAL_STATE', (action: ISetModalStateAction['payload']) => action);

import React, { FunctionComponent, useEffect } from 'react';
import { Background, Content } from './Modal.styled';
import CloseButton from './subComponents/ModalCloseButton/ModalCloseButton';
import { useSelector } from 'react-redux';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useScreen from '@Utils/hooks/useScreen';

export const modals = {
	addedToCart: require('./modals/AddedToCart/AddedToCart'),
	deliveryAddress: require('./modals/DeliveryAddress/DeliveryAddress'),
	genericError: require('./modals//GenericError/GenericError'),
	imagePreview: require('./modals/ImagePreview/ImagePreview'),
	login: require('./modals/Login/Login'),
	loginSessionExpired: require('./modals/LoginSessionExpired/LoginSessionExpired'),
	notifyAvailability: require('./modals/NotifyAvailability/NotifyAvaliability'),
	planSelector: require('./modals/PlanSelector/PlanSelector'),
	productAvailability: require('./modals/ProductAvailability/ProductAvailability'),
	deletePaymentToken: require('./modals/DeletePaymentToken/DeletePaymentToken'),
	showOnMap: require('./modals/ShowOnMap/ShowOnMap'),
	planDetails: require('./modals/PlanDetails/PlanDetails'),
	upsell: require('./modals/Upsell/Upsell'),
	giftCardCodes: require('./modals/GiftCardCodes/GiftCardCodes'),
	selectNumber: require('./modals/SelectNumber/SelectNumber'),
	additionalProduct: require('./modals/AdditionalProduct/AdditionalProduct'),
	telesalesOtp: require('./modals/TelesalesOtp/TelesalesOtp'),
	status: require('./modals/Status/Status'),
	termsAndConditions: require('./modals/TermsAndConditions/TermsAndConditions'),
	OTP: require('./modals/OTP/OTP'),
	outletAvailable: require('./modals/OutletAvailable/OutletAvailable'),
	pickupBooking: require('./modals/PickupBooking/PickupBooking'),
	cancelOrder: require('./modals/CancelOrder/CancelOrder'),
	makasibWarning: require('./modals/MakasibWarning/MakasibWarning'),
	weakPassword: require('./modals/WeakPassword/WeakPassword'),
	microsoftCardDetails: require('./modals/MicrosoftCardDetails/MicrosoftCardDetails'),
};

const Modal: FunctionComponent = () => {
	const modal = useSelector((state: types.redux.IState) => state.modal);
	const { screenClass } = useScreen();

	useEffect(() => {
		// Prevent scrolling body when any modal is open
		if (modal.modalList.length) {
			document.body.style.overflowY = screenClass === 'xs' ? 'unset' : 'scroll';
			document.body.style.position = 'fixed';
			document.body.style.width = '100%';
		} else {
			document.body.style.overflowY = 'unset';
			document.body.style.position = 'unset';
			document.body.style.width = '100%';
		}
	}, [modal]);

	const content = (modalData: types.redux.ISingleModal) => {
		if (modals[modalData.type]) {
			const ModalContent = modals[modalData.type].default;
			return <ModalContent {...modalData.data} />;
		} else {
			return null;
		}
	};

	const renderModal = (modal: types.redux.ISingleModal) => {
		if (modal.withoutContainer) {
			return content(modal);
		} else {
			return <Content>{content(modal)}</Content>;
		}
	};

	return (
		<>
			{modal.modalList.map((modal, index, array) => {
				return (
					<ConditionalRender
						key={index}
						show={!!array.length}
						onTrue={() => (
							<Background color="black54">
								<CloseButton />
								{renderModal(modal)}
							</Background>
						)}
					/>
				);
			})}
		</>
	);
};

export default Modal;

import { IAddress } from '@ApiModels/commerceProfile';
import { IItem } from '@ApiModels/productsByCategoryId';
import Card from '@Components/Card/Card';
import { MS_PRODUCT_QUERY_PARAM } from '@Components/Card/cards/CheckoutSummary/CheckoutSummary';
import { IFormikFields, UPDATE_PROFILE } from '@Components/Card/cards/ContactDetails/ContactDetails';
import { IDocumentDetailsFormikFields } from '@Components/Card/cards/DocumentDetails/DocumentDetails';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Divider from '@Components/Divider/Divider';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import {
	clearCheckoutDetails,
	ICheckout as ICheckoutAction,
	setCheckoutDetails,
} from '@Redux/modules/checkout/actions';
import {
	clearPersistentCheckoutDetails,
	setPersistentCheckoutDetails,
} from '@Redux/modules/persistentCheckoutData/actions';
import { setCheckoutTimer } from '@Redux/modules/settings/settingsCheckout/actions';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { PaymentService } from '@Services/payment/payment';
import { ProductsService } from '@Services/productsService/productsService';
import { useCart } from '@Utils/context/CartContext';
import useAddressBook from '@Utils/hooks/useAddressBook';
import useCommerceProfile from '@Utils/hooks/useCommerceProfile';
import useScreen from '@Utils/hooks/useScreen';
import useTranslate from '@Utils/hooks/useTranslate';
import { getLang } from '@Utils/language/language';
import cardDataValidator from '@Utils/validators/cardDataValidator';
import { BUY_HBB, BUY_WFBB } from '@Views/Map/Map';
import { FormikProps } from 'formik';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Hidden, Row, Visible } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import {
	BottomSection,
	MobileSectionName,
	SectionName,
	StickyWrapper,
	WarningContainer,
	WarningContainerTooltip,
} from './Checkout.styled';
import { setNewAddressBook } from '@Redux/modules/addressBook/actions';
import { INSURANCE_REQUEST_TYPE, MAKASIB_POINTS_PRODUCT_ID, POSTPAID_PLAN } from '@Config/app.config';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { VIEW_CART_ITEMS } from '@Views/Cart/Cart';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import useTelesales from '@Utils/hooks/useTeleasales';
import getHbbWfbbPayload from '@Utils/converters/getHbbWfbbPayload';
import { DISABLE_PICKUP_BOOKING_APPOINTMENT, DISABLE_PICKUP_FROM_OUTLET } from '@Config/features.config';
import { useOutlet } from '@Utils/context/OutletContext';
import { BodyS } from '@Components/Typography/Typography.styled';
import Icon from '@Components/Icon/Icon';
import TagManager from 'react-gtm-module';
import { vatCalculate } from '@Utils/converters/vatCalculate';
import { getUserId } from '@Redux/modules/api/authentication/selectors';
import { AddressesService, ICities } from '@Services/addresses/addressesService';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';
import { AuthenticationService } from '@Services/authentication/authenticationService';
import { ProfileService } from '@Services/profile/profileService';
import { setBuyNowPayLater } from '@Redux/modules/buyNowPayLater/actions';
import { Subscription } from 'rxjs';
import { isDisabledBnplCr7985 } from '@Config/features.config';
import { remoteConfigSelector } from '@Redux/modules/settings/remoteConfig/selectors';
import { paymentRedirection } from '@Utils/payment/payment';
import { externalLoggingApi } from '@Utils/externalLoggingFailure/externalLoggingApi';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const SET_ORDER_BI = 'SET_ORDER';
const ITEM = 'ITEM';
const DEVICE_INSU = 'DEVICE_INSU';
const MAKASIB_PAYMENT_OPERATION_CONTEXT = 'MAKASIBPAYMENTESHOP';
const OPERATION_ID = '18';

enum AddressChangeStatus {
	INITIAL = 'INITIAL',
	CHANGED = 'CHANGED',
}

const Checkout: FunctionComponent = () => {
	const [disableCheckoutButton, setDisableCheckoutButton] = useState(false);
	const { cart, loading } = useCart();
	const [acceptedTC, setTCAccepted] = useState<boolean>(false);
	const [paymentData, setPaymentData] = useState<ICheckoutAction['payload']['paymentData']>();
	const { screen, screenClass } = useScreen();
	const {
		isBuyNowPayLaterInCart,
		isBuyNowPayLaterIframe,
		buyNowPayLaterSessionId,
		buyNowPayLaterIframeUrl,
	} = useBuyNowPayLater();
	const { creditRating, coRelationId } = useSelector((state: types.redux.IState) => state.api.personalData);
	const [addressChangeStatus, setAddressChangeStatus] = useState<AddressChangeStatus>(AddressChangeStatus.INITIAL);
	const hbbWfbbProcessCheckout = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.hbbWfbbProcessCheckout
	);

	const prepaidProcessCheckout = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.prepaidProcessCheckout
	);
	const history = useHistory();
	const dispatch = useDispatch();
	const { translate } = useTranslate();
	const { step } = useParams<{ step: string }>();
	const nextStep = Number(step) + 1;
	const paymentDataFromStore = useSelector((state: types.redux.IState) => state.checkout.paymentData);
	const pickupDelivery = useSelector((state: types.redux.IState) => state.checkout.pickup);
	const outletPickupFocus = useSelector((state: types.redux.IState) => state.addressBook.outletPickupFocus);
	const cartUpdated = useSelector((state: types.redux.IState) => state.cart.cartUpdated);
	const paymentSchema = cardDataValidator();
	const contactDetailsRef = useRef<FormikProps<IFormikFields>>(null);
	const documentDetailsRef = useRef<FormikProps<IDocumentDetailsFormikFields>>(null);
	const [hasGiftCards, setHasGiftCards] = useState<boolean>(false);
	const [hasDevice, setHasDevice] = useState(false);
	const [hasDigitalProduct, setHasDigitalProduct] = useState<boolean>(false);
	const { contactDetails, updateProfile } = useCommerceProfile({
		busyIndicatorListener: GET_USER_PROFILE,
	});
	const [personTitleId, setPersonTitleId] = useState(contactDetails?.title ?? '');
	const [products, setProducts] = useState<IItem[]>([]);
	const [orderInProgress, setOrderInProgress] = useState(false);
	const [additionalLandmark, setAdditionalLandmark] = useState<string>(hbbWfbbProcessCheckout?.landmark || '');
	const isGuest = useSelector((state: types.redux.IState) => state.api.authentication.loginType === 'guest');
	const isLogged = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const [saveAddress, setSaveAddress] = useState<boolean>(isGuest);
	const { isTelesales, personalizationId, token, trustedToken } = useTelesales();
	const remoteFeatureConfig = useSelector(remoteConfigSelector);
	const DISABLE_BNPL_CR_ODF_7985 = isDisabledBnplCr7985(remoteFeatureConfig);
	const [cities, setCities] = useState<ICities[]>([]);
	const isApplePayAllow = useSelector((state: types.redux.IState) => state.api.authentication.isApplePayAllow);
	const {
		getAvailableOutlets,
		selectedOutlet,
		bookingPickupAppointment,
		ticketNumber,
		alertVisibility,
		setAlertVisibility,
		setUserContactDetails,
		disabledDates,
		cancelCurrentAppointment,
		bookNewAppointment,
	} = useOutlet();
	const { nationality } = useSelector((state: types.redux.IState) => state.api.personalData);
	const preorderDate = cart?.items[0]?.plan?.preorderDate;
	const orderIncludesPlan = cart.items.some(
		(item) => item.withPlan && ![BUY_HBB, BUY_WFBB].includes(item.plan?.PA || '')
	);
	const onlyGiftCardOrDigitals = products.every((product) => product?.isDigitalProduct || product?.isGiftCard);
	const onlyPickupItems = products.every((product) => product?.pickupOrderAvailable || product?.isGiftCard);
	const launchDate = products.find((product) => !!product?.launchDate)?.launchDate;

	const { selectedAddress, editAddress } = useAddressBook();

	const hbbWfbbItem = useMemo(() => {
		return cart.items.find((item) => [BUY_HBB, BUY_WFBB].includes(item.plan?.PA || ''));
	}, [cart]);

	const isWfbbProcess = useMemo(() => {
		return !!cart.items.find((item) => [BUY_WFBB].includes(item.plan?.PA || ''));
	}, [cart]);

	const isPrepaidProcess = useMemo(() => {
		return !!cart.items.find((item) => [POSTPAID_PLAN].includes(item.plan?.PA || ''));
	}, [cart]);

	const isBuyHbbWfbbProcess = !!hbbWfbbItem;

	const moreItemInCart = useMemo(() => {
		return cart.items.length > 1;
	}, [cart]);

	useEffect(() => {
		dispatch(
			setCheckoutDetails({
				onlyGiftCardOrDigitals,
			})
		);

		dispatch(
			setNewAddressBook({
				newAddressBook: '',
			})
		);
	}, [onlyGiftCardOrDigitals, cartUpdated]);

	const onlyDigitalProductInCart = (hasGiftCards || hasDigitalProduct) && !hasDevice && !isBuyHbbWfbbProcess;

	const hasInsuranceInCart = useMemo(() => {
		return !!cart.items.find((cartItem) => INSURANCE_REQUEST_TYPE.includes(cartItem.partNumber));
	}, [cart]);

	useEffect(() => {
		document.body.style.overflowX = 'unset';
		return () => {
			document.body.style.overflowX = 'hidden';
		};
	}, []);

	useEffect(() => {
		AddressesService.getCities().subscribe(
			(response) => {
				setCities(response);
			},
			() => {}
		);
	}, []);

	const areas = useMemo(() => {
		return (
			cities
				.find((city) => city.key === selectedAddress?.wilayat)
				?.cities.find((item) => item.key === selectedAddress?.city)
				?.areas?.map((area) => ({
					text: area.name_en,
					id: area.key,
				})) ?? []
		);
	}, [cities, selectedAddress]);

	useEffect(() => {
		const products: IItem[] = [];
		if (!orderInProgress) {
			if (cart.items.length) {
				cart.items.map((cartItem, index) => {
					ProductsService.getProductById({ productId: cartItem.productId }).subscribe(
						({ item }) => {
							products.push(item);
							if (item?.isGiftCard) {
								setHasGiftCards(true);
							}
							if (item?.isDigitalProduct) {
								setHasDigitalProduct(true);
							}
							if (item && !item?.isDigitalProduct && !item?.isGiftCard) {
								setHasDevice(true);
							}
							if (cart?.items?.length === index + 1) {
								setProducts(products);
							}
						},
						() => {}
					);
				});
			}
		}
	}, [loading]);

	useEffect(() => {
		if (!orderInProgress) {
			if (!cart.items.length) {
				// history.push(`/${getLang()}/store`); ????
			}
		}
	}, []);

	useEffect(() => {
		dispatch(setModal({ closeAllModals: true }));
		if (isTelesales && cart.items.some(({ lock }) => !!lock)) {
			dispatch(
				setModal({
					type: modalTypes.TELESALES_OTP,
					withoutContainer: true,
					availableClosing: false,
				})
			);
		}
	}, [isTelesales, cart]);

	useEffect(() => {
		if (!loading) {
			const partIds = cart.items.map((item) =>
				item.defType?.includes(ITEM) && !item.partNumber.includes(DEVICE_INSU) ? item.partNumber : undefined
			);
			getAvailableOutlets(partIds);
		}
	}, [cart.items, loading]);

	const handleUpdatePaymentData = (paymentData: ICheckoutAction['payload']['paymentData']) => {
		setPaymentData(paymentData);
	};

	const setHbbWfbbOrder = ({ orderId }: { orderId: string }) => {
		dispatch(addBusyIndicator(SET_ORDER_BI));
		const allParams = { ...hbbWfbbProcessCheckout, orderId };
		const payload = getHbbWfbbPayload(allParams, isWfbbProcess);
		OrderFlowService.setHbbWfbbOrder(payload, isWfbbProcess).subscribe(
			(response) => {
				dispatch(clearPersistentCheckoutDetails());
				dispatch(removeBusyIndicator(SET_ORDER_BI));
				const requestNumber = response.requestId || response.internalRequestId;
				if (requestNumber) pushRouteOnSuccess(orderId, requestNumber);
				else {
					history.push(`/${getLang()}/payment/failure/hbbwfbb?orderId=${orderId}`);
				}
			},
			(e) => {
				dispatch(removeBusyIndicator(SET_ORDER_BI));
				history.push(`/${getLang()}/payment/failure/hbbwfbb?orderId=${orderId}`);
			}
		);
	};

	const makasibItem = useMemo(() => {
		return cart.items.find((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID);
	}, [cart.items]);

	const pushRouteOnSuccess = (orderId: string, requestId?: string) => {
		history.push(
			`/${getLang()}/payment/success?orderId=${orderId}${preorderDate ? '&preorder=' + preorderDate : ''}${
				hasGiftCards ? '&giftcards=true' : ''
			}${hasDigitalProduct ? `&${MS_PRODUCT_QUERY_PARAM}=true` : ''}${
				requestId ? `&hbbwfbboffer=${requestId}` : ''
			}`
		);
	};

	const handleSetOrder = () => {
		let summaryVat = 0;
		const payload = cart.items.map((product) => {
			summaryVat = summaryVat + vatCalculate(product);
			return {
				productSkuId: product.productId,
				productId: product.productParentId,
				productQuantity: product.quantity,
				productPrice: ((Number(product.price) - vatCalculate(product)) / product.quantity ?? 0) || 0,
			};
		});
		sessionStorage.clear();
		sessionStorage.setItem('products', JSON.stringify(payload));
		TagManager.dataLayer({
			dataLayer: {
				event: 'checkoutViewProducts',
				checkoutViewProducts: {
					products: payload,
					totalPurchaseValue: Number(cart.totalProductPrice) - summaryVat,
				},
				userId: getUserId(),
			},
		});
		dispatch(addBusyIndicator(SET_ORDER_BI));
		dispatch(setCheckoutTimer({ checkoutTimer: undefined }));
		PaymentService.doCheckout().subscribe(
			(r) => {
				if ((isBuyHbbWfbbProcess && moreItemInCart) || !isBuyHbbWfbbProcess) {
					const makePaymentProps: types.payment.IMakePayment = {
						address: selectedAddress,
						contactDetails,
						orderId: r.orderId,
						paymentData: paymentDataFromStore,
						totalPrice: Number(cart.totalProductPrice),
						flowType:
							paymentData?.paymentMethod === 'applePay' && isApplePayAllow
								? 'APPLEPAY'
								: 'OMANNET_PREFFERRED',
					};
					PaymentService.makePayment({ makePaymentProps, origin: 'cart' }).subscribe(
						() => {
							dispatch(removeBusyIndicator(SET_ORDER_BI));
							dispatch(clearCheckoutDetails());
							dispatch(setCheckoutTimer({ checkoutTimer: undefined }));
							if (
								isBuyHbbWfbbProcess ||
								(hbbWfbbProcessCheckout?.region && hbbWfbbProcessCheckout.longitude)
							) {
								dispatch(
									setPersistentCheckoutDetails({ hbbWfbbProcessCheckout: { orderId: r.orderId } })
								);
								setHbbWfbbOrder({ orderId: r.orderId });
							} else {
								pushRouteOnSuccess(r.orderId);
								dispatch(clearPersistentCheckoutDetails());
							}
						},
						(error) => {
							dispatch(removeBusyIndicator(SET_ORDER_BI));
							dispatch(setCheckoutTimer({ checkoutTimer: moment().unix() }));
							switch (error.response.code) {
								case 'PAYER_AUTHENTICATION_REQUIRED':
									paymentRedirection(error?.response?.url);
									break;
								case 'TRANSACTION_REJECTED':
									history.push(`/${getLang()}/payment/failure?orderId=${r.orderId}`);
									break;
								case 'ORDER_ALREADY_PLACED_BEFORE':
								case 'INVALID_CARD_DATA':
									history.push(
										`/${getLang()}/payment/failure?orderId=${r.orderId}&error=${
											error.response.code
										}`
									);
									break;
								default:
									history.push(
										`/${getLang()}/payment/failure?orderId=${
											r.orderId
										}&error=GENERIC_PRE_VALIDATION_ERROR`
									);
									break;
							}
						}
					);
				} else {
					setHbbWfbbOrder({ orderId: r.orderId });
				}
			},
			() => {
				dispatch(removeBusyIndicator(SET_ORDER_BI));
				history.push(`/${getLang()}/payment/failure`);
			}
		);
	};

	const handleNextStep = async () => {
		if (!step && isTelesales) {
			history.push(`/${getLang()}/checkout/1`);
		} else {
			switch (step) {
				case '1':
					await handleStepOne();
					return;
				case '2':
					handleStepTwo();
					return;
				case '3':
					handleStepThree();
					return;
				case '4':
					handleStepFour();
					return;
			}
		}
	};

	const handleStepOne = async () => {
		if (isBuyHbbWfbbProcess || isPrepaidProcess) {
			const documentErrorFileds = await documentDetailsRef?.current?.validateForm();
			const contactDetailsErrorFields = await contactDetailsRef?.current?.validateForm();
			const documentHasErrors = documentErrorFileds && !!Object.keys(documentErrorFileds).length;
			const contactDetailsHasErrors =
				contactDetailsErrorFields && !!Object.keys(contactDetailsErrorFields).length;

			if (documentHasErrors) {
				documentDetailsRef.current?.handleSubmit();
			}
			if (contactDetailsHasErrors) {
				contactDetailsRef.current?.handleSubmit();
			}
			if (!documentHasErrors && !contactDetailsHasErrors) {
				if (!isBuyHbbWfbbProcess && documentDetailsRef.current) {
					dispatch(addBusyIndicator(UPDATE_PROFILE));
					OrderFlowService.validateResource({
						customerCode: documentDetailsRef.current.values.customerId,
						customerType: documentDetailsRef.current.values.customerIdType,
						requestType: 'NEW Connection',
						serviceOrderType: 'PREPAID',
					}).subscribe(
						(result) => {
							dispatch(removeBusyIndicator(UPDATE_PROFILE));
							if (result?.response) {
								documentDetailsRef.current?.handleSubmit();
								contactDetailsRef.current?.handleSubmit();
							}
						},
						() => {
							dispatch(removeBusyIndicator(UPDATE_PROFILE));
							dispatch(
								setModal({
									type: modalTypes.GENERIC_ERROR,
									data: {
										icon: 'error',
										iconFill: 'warning',
										title: translate('prepaid.civil.number.validation.error.title'),
										description: translate('prepaid.civil.number.validation.error.description'),
									},
								})
							);
						}
					);
				} else {
					documentDetailsRef.current?.handleSubmit();
					contactDetailsRef.current?.handleSubmit();
				}
			}
		} else {
			contactDetailsRef.current?.handleSubmit();
			if (!!contactDetailsRef?.current) {
				setUserContactDetails({
					firstName: contactDetailsRef?.current?.values.firstName,
					middleName: contactDetailsRef?.current?.values.middleName,
					lastName: contactDetailsRef?.current?.values.lastName,
					phoneNumber: contactDetailsRef?.current?.values.phoneNumber,
				});
			}
		}
	};

	useEffect(() => {
		if (prepaidProcessCheckout && isPrepaidProcess)
			OrderFlowService.changeCartItemData({
				documentType: prepaidProcessCheckout.customerIdType,
				documentId: prepaidProcessCheckout.customerId,
				orderItemId: prepaidProcessCheckout.orderItemId,
			}).subscribe(
				() => {},
				() => {}
			);
	}, [prepaidProcessCheckout]);

	const chooseCybersourcePayment = () => {
		OrderFlowService.chooseCybersourcePayment().subscribe(
			() => {},
			() => {}
		);
	};

	const chooseARAMEXShipment = (addressId: string) => {
		OrderFlowService.chooseARAMEXShipment({ addressId, pickupDelivery }).subscribe(
			() => {
				if (isBuyNowPayLaterInCart) {
					setAddressChangeStatus(AddressChangeStatus.CHANGED);
				}
			},
			() => {}
		);
	};

	const hbbWfbbInternetInstallationAddress: IAddress = useMemo(() => {
		return {
			organization: '',
			governorate: hbbWfbbProcessCheckout?.region || '',
			wilayat: hbbWfbbProcessCheckout?.willyat || '',
			city: hbbWfbbProcessCheckout?.swatSOSStatus || '',
			way: hbbWfbbProcessCheckout?.wayNumber || '',
			house: hbbWfbbProcessCheckout?.buildingNumber || '',
			landmark: additionalLandmark,
			area: hbbWfbbProcessCheckout?.area || '',
			markerPosition: {
				lat: Number(hbbWfbbProcessCheckout?.latitude || 0),
				lng: Number(hbbWfbbProcessCheckout?.longitude || 0),
			},
			// addressId: '', ???
			// nickName: '', ???
			// addressType: '', ???
			// zipCode: '', todo: hbbWfbbProcessCheckout?.swatSOSStatus, zapytac Tomka czy jest konieczny
		};
	}, [hbbWfbbProcessCheckout, additionalLandmark]);

	const hbbWfbbContactForm: types.redux.IState['checkout']['contactDetails'] = useMemo(() => {
		const reduxContactDetails = {
			email: hbbWfbbProcessCheckout?.emailId || '',
			title: contactDetails?.title || '',
			firstName: hbbWfbbProcessCheckout?.firstName || '',
			middleName: hbbWfbbProcessCheckout?.middleName || '',
			lastName: hbbWfbbProcessCheckout?.customerName || '',
			phoneNumber: hbbWfbbProcessCheckout?.contactNumber1 || '',
			birthDate: hbbWfbbProcessCheckout?.birthDate || '',
			fatherName: hbbWfbbProcessCheckout?.fatherName || '',
			grandfatherName: hbbWfbbProcessCheckout?.grandfatherName || '',
		};
		return { ...contactDetails, ...reduxContactDetails };
	}, [hbbWfbbProcessCheckout]);

	useEffect(() => {
		if (
			isBuyNowPayLaterInCart &&
			!!buyNowPayLaterSessionId &&
			!!buyNowPayLaterIframeUrl &&
			isBuyNowPayLaterIframe
		) {
			history.push(`/${getLang()}/buy-now-pay-later`);
		}
	}, [isBuyNowPayLaterInCart, isBuyNowPayLaterIframe]);

	useEffect(() => {
		let subscription: Subscription | undefined;
		subscription = undefined;
		subscription = getBuyNowPayLaterSessionId();
		return () => {
			subscription?.unsubscribe();
		};
	}, [addressChangeStatus]);

	const getBuyNowPayLaterSessionId = () => {
		if (addressChangeStatus === AddressChangeStatus.CHANGED) {
			chooseCybersourcePayment();
			dispatch(addBusyIndicator(UPDATE_PROFILE));
			return ProfileService.getUserProfile().subscribe(
				(response) => {
					AuthenticationService.createBuyNowPayLaterSession({
						orderId: cart.orderId,
						dateOfBirth: moment(response.dateOfBirth).format('YYYY-MM-DD'),
						creditRating,
						isDisabledBnplCr7985: DISABLE_BNPL_CR_ODF_7985,
						coRelationId,
					}).subscribe(
						(response) => {
							if (!!response.session.BNPIURL && !!response.session.BNPIURL) {
								dispatch(
									setBuyNowPayLater({
										buyNowPayLaterSessionId: response.session.BNPLSI.replace(/\\\//g, ''),
										buyNowPayLaterIframeUrl: response.session.BNPIURL,
										isBuyNowPayLaterIframe: true,
									})
								);
								setAddressChangeStatus(AddressChangeStatus.INITIAL);
							}
							dispatch(removeBusyIndicator(UPDATE_PROFILE));
						},
						(err) => {
							{
								dispatch(
									setModal({
										type: modalTypes.GENERIC_ERROR,
										data: {
											icon: 'warning',
											iconFill: 'primary',
											title: translate('checkout-summary.something.went.wrong.title'),
										},
									})
								);
								externalLoggingApi({
									functionalFlow: 'BNPL',
									warningText: 'BNPL Thawani Create Session Failure',
									errorCode: '1005',
									request: JSON.stringify({
										orderId: cart.orderId,
										dateOfBirth: moment(response.dateOfBirth).format('YYYY-MM-DD'),
										creditRating,
										isDisabledBnplCr7985: DISABLE_BNPL_CR_ODF_7985,
									}),
									response: JSON.stringify(err),
								});
							}

							dispatch(removeBusyIndicator(UPDATE_PROFILE));
							setAddressChangeStatus(AddressChangeStatus.INITIAL);
						}
					);
				},
				() => {
					dispatch(removeBusyIndicator(UPDATE_PROFILE));
					setAddressChangeStatus(AddressChangeStatus.INITIAL);
				}
			);
		}
	};

	const handleStepTwo = () => {
		if (onlyGiftCardOrDigitals || pickupDelivery) {
			updateProfile({ contactDetails }, chooseARAMEXShipment);
			dispatch(
				setCheckoutDetails({
					paymentData,
				})
			);
			chooseCybersourcePayment();
		} else {
			if (isBuyHbbWfbbProcess) {
				dispatch(setPersistentCheckoutDetails({ hbbWfbbProcessCheckout: { landmark: additionalLandmark } }));
				updateProfile(
					{
						billingAddress: hbbWfbbInternetInstallationAddress,
						contactDetails: hbbWfbbContactForm,
						updateShipping: true,
					},
					chooseARAMEXShipment
				);
			} else {
				if (
					!!selectedAddress?.area &&
					areas.some((item) => item.id === selectedAddress?.area) &&
					!selectedOutlet.branchId
				) {
					updateProfile({ billingAddress: selectedAddress, contactDetails }, chooseARAMEXShipment);
				} else {
					if (!!selectedAddress && !selectedOutlet.branchId) {
						editAddress(selectedAddress, true);
					}
				}
			}
		}
		if (cart.totalProductPrice === '0') {
			chooseCybersourcePayment();
			history.push(`/${getLang()}/checkout/${nextStep + 1}`);
		} else {
			if (
				onlyGiftCardOrDigitals ||
				pickupDelivery ||
				(!!selectedAddress?.area && areas.some((item) => item.id === selectedAddress?.area)) ||
				!!selectedOutlet.branchId
			) {
				if (!isBuyNowPayLaterInCart) {
					history.push(`/${getLang()}/checkout/${nextStep}`);
				}
			}
		}
		if (!!selectedOutlet.branchId && !DISABLE_PICKUP_FROM_OUTLET) {
			OrderFlowService.changeCartItemData({
				branchId: selectedOutlet.branchId,
				bookingPickupAppointment,
				orderItemId: cart.items[0].orderItemId,
				ticketNumber,
			}).subscribe(
				() => {},
				() => {}
			);
		}
	};

	const handleStepThree = () => {
		dispatch(
			setCheckoutDetails({
				paymentData,
			})
		);
		chooseCybersourcePayment();
		history.push(`/${getLang()}/checkout/${nextStep}`);
	};

	const handleStepFour = () => {
		history.push(`/${getLang()}/checkout/${nextStep}`);
	};

	const cartItems = useMemo(() => {
		return cart.items;
	}, [cart]);

	const telesalesStepOne = () => (
		<>
			<Card<types.cards.ITelesalesDescriptionProps>
				card="telesalesDescription"
				params={{
					description: translate('telesales-checkout.view.voice.description'),
				}}
				marginBottom={16}
			/>
			<BusyIndicator listener={VIEW_CART_ITEMS} skeleton="cart">
				<ConditionalRender
					show={!!cart.items.length}
					onTrue={() => (
						<Card<types.cards.ICartItemsCardProps>
							card="cartItems"
							params={{
								cartItems,
								tableMode: true,
								addToCart: false,
								disableCheckoutButton: (value) => {
									setDisableCheckoutButton(value);
								},
								withoutQuantityButtons: isBuyHbbWfbbProcess,
								showPlanDetailsAndRemoveButton: isBuyHbbWfbbProcess,
							}}
							marginBottom={16}
						/>
					)}
				/>
			</BusyIndicator>
			<ConditionalRender
				show={!isLogged}
				onTrue={() => (
					<Card<types.cards.ITelesalesLoginProps> card="telesalesLogin" params={{}} marginBottom={16} />
				)}
			/>
		</>
	);

	const stepOne = () => (
		<>
			<ConditionalRender
				show={isBuyHbbWfbbProcess}
				onTrue={() => (
					<>
						<Card<types.cards.IDocumentDetailsProps>
							card="documentDetails"
							params={{ documentDetailsRef }}
						/>
						<Divider withoutLine marginBottom={16} />
					</>
				)}
			/>
			<ConditionalRender
				show={isPrepaidProcess}
				onTrue={() => (
					<>
						<Card<types.cards.IDocumentDetailsProps>
							card="simDocumentDetails"
							params={{ documentDetailsRef }}
						/>
						<Divider withoutLine marginBottom={16} />
					</>
				)}
			/>
			<Card<types.cards.IContactDetailsProps>
				card="contactDetails"
				params={{
					contactDetailsRef,
					personTitleId,
					setPersonTitleId,
					orderIncludesPlan,
					onlyGiftCardOrDigitals,
					isHbbWfbbProcess: !!hbbWfbbItem,
					isWfbbProcess,
				}}
			/>
			<ConditionalRender
				show={!onlyDigitalProductInCart}
				onTrue={() => (
					<>
						<Divider marginBottom={16} withoutLine />
						<Card<types.cards.ITermsAndConditions>
							card="termsAndConditions"
							params={{
								content: '',
								accepted: acceptedTC,
							}}
							onEvent={(event) => {
								event === 'accept' && setTCAccepted(!acceptedTC);
							}}
						/>
						<Divider marginBottom={screen(['xs', 'sm', 'md']) ? 0 : 64} withoutLine />
					</>
				)}
			/>
		</>
	);

	const stepTwo = () => (
		<>
			<ConditionalRender
				show={isBuyHbbWfbbProcess}
				onTrue={() => (
					<Card<types.cards.IShippingMethodCardProps>
						card="shippingMethod"
						marginBottom={16}
						params={{
							hasDevice,
							orderIncludesPlan,
							onlyPickupItems,
							launchDate,
							hbbWfbbInternetInstallationAddress,
							additionalLandmark,
							setAdditionalLandmark,
							saveAddress,
							setSaveAddress,
							isBuyHbbWfbbProcess: true,
						}}
					/>
				)}
				onFalse={() => (
					<ConditionalRender
						show={(!hasDigitalProduct && hasDevice) || isPrepaidProcess}
						onTrue={() => (
							<Card<types.cards.IShippingMethodCardProps>
								card="shippingMethod"
								marginBottom={16}
								params={{
									orderIncludesPlan,
									onlyPickupItems,
									launchDate,
									isBuyHbbWfbbProcess: false,
									isPrepaidPurchase: isPrepaidProcess,
								}}
							/>
						)}
					/>
				)}
			/>
			<ConditionalRender
				show={hasGiftCards || hasDigitalProduct || hasInsuranceInCart}
				onTrue={() => (
					<Card<types.cards.IDigitalProductShippingMethodCardProps>
						card="digitalProductShippingMethod"
						marginBottom={16}
					/>
				)}
			/>
			<ConditionalRender
				show={
					!hasDigitalProduct &&
					hasDevice &&
					!!outletPickupFocus &&
					!DISABLE_PICKUP_BOOKING_APPOINTMENT &&
					!DISABLE_PICKUP_FROM_OUTLET
				}
				onTrue={() => (
					<>
						<Card<types.cards.IOutletPickupCardProps>
							card="bookAppointment"
							marginBottom={64}
							params={{
								selectedOutletId: selectedOutlet.branchId,
								setAlertVisibility,
								bookingPickupAppointment,
								disabledDates,
								cancelCurrentAppointment,
								bookNewAppointment,
							}}
						/>
						<ConditionalRender
							show={alertVisibility}
							onTrue={() => (
								<>
									<WarningContainerTooltip />
									<WarningContainer>
										<Icon height={16} width={12} name="warningRedFilled" />
										<BodyS color="warning">{translate('book-appointment.outlet.warning')}</BodyS>
									</WarningContainer>
								</>
							)}
						/>
					</>
				)}
			/>
		</>
	);
	const stepThree = () => (
		<>
			<Card<types.cards.IPaymentDataCardProps>
				card="paymentData"
				withoutContainerPadding
				onEvent={(event, data: ICheckoutAction['payload']['paymentData']) => {
					event === 'updatePaymentData' && handleUpdatePaymentData(data);
				}}
			/>
		</>
	);
	const stepFour = () => (
		<Card<types.cards.IOrderSummaryCardProps>
			card="orderSummary"
			withoutContainerPadding
			withoutContainer
			params={{ cart, paymentData }}
		/>
	);

	const content = () => {
		if (!step && personalizationId && token && trustedToken) {
			return telesalesStepOne();
		} else {
			switch (step) {
				case '1':
					return stepOne();
				case '2':
					return onlyGiftCardOrDigitals ? stepThree() : stepTwo();
				case '3':
					return onlyGiftCardOrDigitals ? stepFour() : stepThree();
				case '4':
					return stepFour();
				default:
					return <Redirect to="/store" />;
			}
		}
	};

	const validateCard = (): boolean => {
		if (paymentData?.token && !Object.values(paymentData.token).length) {
			return true;
		}
		if (paymentData?.card && !paymentSchema.isValidSync(paymentData.card)) {
			return true;
		}
		return false;
	};

	const isNextStepButtonDisabled = () => {
		if (step === '1') {
			if (!acceptedTC && !onlyDigitalProductInCart && !isTelesales) {
				return true;
			}
		}
		if (step === '2') {
			if (!!selectedOutlet.branchId) {
				return false;
			}
			if (isBuyHbbWfbbProcess && !hasDevice) {
				return false;
			}
			if (isBuyHbbWfbbProcess && !hasDevice) {
				return false;
			}
			if (pickupDelivery || hasInsuranceInCart) {
				return false;
			}
			if (onlyDigitalProductInCart && validateCard()) {
				return true;
			}
			if (!onlyDigitalProductInCart && selectedAddress && !selectedAddress?.wilayat && !selectedAddress?.way) {
				return true;
			}
		}
		if (step === '3') {
			if (validateCard()) {
				return true;
			}
		}
		return false;
	};

	const xsStyles = screenClass.includes('xs') ? { paddingLeft: 0, paddingRight: 0 } : {};

	const onSetOrder = () => {
		if (!!makasibItem) {
			dispatch(addBusyIndicator(SET_ORDER_BI));
			ProductsService.validateOperation(MAKASIB_PAYMENT_OPERATION_CONTEXT).subscribe(
				({ masterMsisdn }) => {
					const data: types.cards.IOTPCardProps['params'] = {
						msisdn: masterMsisdn,
						isMakasib: true,
						onClickResend: ProductsService.validateOperation(MAKASIB_PAYMENT_OPERATION_CONTEXT),
						handleSetOrder: () => handleSetOrder(),
						setOrderInProgress: () => setOrderInProgress(true),
						operationId: OPERATION_ID,
						orderId: cart.orderId,
					};
					dispatch(removeBusyIndicator(SET_ORDER_BI));
					dispatch(setModal({ type: modalTypes.OTP, withoutContainer: true, data }));
				},
				() => {}
			);
		} else {
			setOrderInProgress(true), handleSetOrder();
		}
	};

	return (
		<>
			<Container>
				<Row>
					<Col>
						<ConditionalRender
							show={screen('xs')}
							onTrue={() => (
								<MobileSectionName>
									{translate(
										isTelesales
											? 'telesales-checkout.view.section.name'
											: 'checkout.view.section.name'
									)}
								</MobileSectionName>
							)}
							onFalse={() => (
								<SectionName>
									{translate(
										isTelesales
											? 'telesales-checkout.view.section.name'
											: 'checkout.view.section.name'
									)}
								</SectionName>
							)}
						/>
					</Col>
				</Row>
				<Row>
					<Col style={xsStyles} lg={9}>
						{content()}
					</Col>
					<Col style={xsStyles} lg={3}>
						<StickyWrapper>
							<Hidden xs sm md>
								<Card<types.cards.ICheckoutNextStep>
									card="checkoutNextStep"
									onEvent={(event) => {
										event === 'nextStep' && handleNextStep();
										event === 'setOrder' && onSetOrder();
									}}
									params={{
										buttonDisabled: isNextStepButtonDisabled(),
										onlyGiftCardOrDigitals,
									}}
								/>
							</Hidden>
							<Card<types.cards.IOrderCardProps>
								card="order"
								marginTop={16}
								marginBottom={16}
								params={{ withPlan: orderIncludesPlan, cart }}
							/>
						</StickyWrapper>
					</Col>
				</Row>
			</Container>
			<Visible xs sm md>
				<BottomSection>
					<Card<types.cards.ICheckoutNextStep>
						card="checkoutNextStep"
						onEvent={(event) => {
							event === 'nextStep' && handleNextStep();
							event === 'setOrder' && onSetOrder();
						}}
						params={{
							buttonDisabled: isNextStepButtonDisabled(),
							isBuyHbbWfbbProcess,
							isPrepaidProcess,
						}}
					/>
				</BottomSection>
			</Visible>
		</>
	);
};

export default Checkout;

import React, { useState, FunctionComponent, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'react-grid-system';
import {
	CardWrapper,
	SpaceBetweenContainer,
	PlanBarWrapper,
	PlanContainer,
	Title,
	WithPlanContainer,
	ExpirationWrapper,
	ExpireContainer,
	IconWrapper,
	PlanEditWarning,
	PlanEditWarningTitle,
	PlanEditWarningDescriptionUnderline,
	BillingTypeWrapper,
	AdditionalInfoTitle,
	PlanBarSubtitle,
	InstallmentsButtonsWrapper,
	UpfrontInstallmentButton,
	UpgradeContainer,
	MarginContainer,
	OuterBanner,
	BannerImg,
} from './Plan.styled';
import SegmentSelector from '@Components/SegmentSelector/SegmentSelector';
import useTranslate from '@Utils/hooks/useTranslate';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import {
	BodySBold,
	ButtonM,
	Caption,
	CaptionBold,
	ParagraphM,
	ParagraphSBold,
	Subheading,
	Value,
} from '@Components/Typography/Typography';
import { PlanBarType } from '@Components/PlanBar/PlanBarType';
import PlanBar from '@Components/PlanBar/PlanBar';
import Icon from '@Components/Icon/Icon';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { useDispatch } from 'react-redux';
import { AccountsService } from '@Services/accounts/accountsService';
import { PackagesService } from '@Services/packages/packages';
import { IAccounts, IAccount, BillingType, AccountType } from '@ApiModels/accounts';
import { IDropdownItem, RegularDropdown } from '@Components/controls/Dropdown/Dropdown';
import { DropdownHeight, LabelType } from '@Components/controls/Dropdown/Dropdown.styled';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { ISinglePackage } from '@ApiModels/singlePackage';
import { ICustomerProducts, TARIFF_PACKAGE } from '@ApiModels/customerProducts';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import moment from 'moment';
import { setSelectedItems } from '@Redux/modules/selectedItems/actions';
import { setB2bUser, setB2b2cUser } from '@Redux/modules/api/authentication/actions';
import { getLang } from '@Utils/language/language';
import { IProductByIdItem } from '@ApiModels/productById';
import {
	CUSTOMER_CARE_PHONE,
	AL_UFUQ_SKU_TO_REMOVE,
	OMANTEL_PHONE_NUMBER_PREFIX,
	B2B2C_CATEGORIES,
	CUSTOMER_B2B2C_CARE_PHONE,
} from '@Config/app.config';
import { TertiaryMediumButtonAuto } from '@Components/controls/Button/Button';
import MessageBar from '@Components/MessageBar/MessageBar';
import { GET_PRODUCT_DETAILS_BI } from '@Views/ProductPage/ProductPage';
import UpgradePlan from '@Components/UpgradePlan/UpgradePlan';
import Divider from '@Components/Divider/Divider';
import { ProfileService } from '@Services/profile/profileService';
import { ProductsService } from '@Services/productsService/productsService';
import MultipleSegmentSelector, {
	ISingleSegmentProps,
} from '@Components/MultipleSegmentSelector/MultipleSegmentSelector';
import { ISegmentSize } from '@Components/controls/Segment/Segment';
import TagManager from 'react-gtm-module';
import {
	DISABLE_EBU_SINGLE_PURCHASE,
	DISABLE_EBU_SINGLE_PURCHASE_B2B2C_BAQATI,
	DISABLE_EBU_SINGLE_PURCHASE_B2B_BAQATI,
	ENABLE_4G5G,
	isNGN24Months,
	isNGN24MonthsSmartphones,
	isNGN24MonthsNoSmartphones,
	isNGN24MonthsHBB,
	isNGN24MonthsWFBB,
	isNewPCTLaunch,
	isBNPLCreditFailure,
} from '@Config/features.config';
import { Subscription } from 'rxjs';
import { getUserId } from '@Redux/modules/api/authentication/selectors';
import useScreen from '@Utils/hooks/useScreen';
import { setPersonalData } from '@Redux/modules/api/personalData/actions';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';
import { CreditRating } from '@Config/app.config';
import bnplbanner from '@Assets/images/bnplBanner.png';
import arabicBnplBanner from '@Assets/images/arabicBnplBanner.png';
import { remoteConfigSelector } from '@Redux/modules/settings/remoteConfig/selectors';
export enum PlanType {
	DEVICE_ONLY = 'DEVICE_ONLY',
	WITH_PLAN = 'WITH_PLAN',
}

interface ITelesalesContext {
	telesalesCustomerBillingType?: BillingType;
	telesalesCustomerAccountType?: AccountType;
	telesalesCustomerAccountCategory?: string;
}

const ICON_24 = 24;
const ICON_64 = 64;
const TIMEOUT_1000 = 1000;
export const VALIDATE_OFFERS = false; // As per Manudeep request validation is disabled -> MINT-6400
export const GET_ACCOUNT_FOR_LOGGED_USER = 'GET_ACCOUNT_FOR_LOGGED_USER';
export const GET_RECOMMENDED_PLAN = 'GET_RECOMMENDED_PLAN';
export const INFINITE_LOADING = 'INFINITE_LOADING';
export const NOT_CHOSEN_NUMBER = 'NOT_CHOSEN_NUMBER';
export const GET_PLANS = 'GET_PLANS';
export const GET_CUSTOMER_PRODUCTS = 'GET_CUSTOMER_PRODUCTS';
export const VALIDATE_OFFER = 'VALIDATE_OFFER';
export const GET_HIGHER_PLAN = 'GET_HIGHER_PLAN';
export const VALIDATE_CHANGED_INSTALLMENT_SKUS = 'VALIDATE_CHANGED_INSTALLMENT_SKUS';
export const CHECK_IF_CAN_GET_NEW_PLAN = 'CHECK_IF_CAN_GET_NEW_PLAN';
export const GET_PRICE_BY_CAT_ENTRY_BI = 'GET_PRICE_BY_CAT_ENTRY_BI';
export const GET_ACCOUNT_INFO_BI = 'GET_ACCOUNT_INFO_BI';
export const GET_TELESALES_ACCOUNT_INFO_BI = 'GET_TELESALES_ACCOUNT_INFO_BI';
export const SHOW_SUMMARY_INDICATOR = 'SHOW_SUMMARY_INDICATOR';
export const GET_SMART_VOUCHER = 'GET_SMART_VOUCHER';
export const SELECT_ACCOUNT = 'SELECT_ACCOUNT';
export const EVENT_UPGRADE_NOW = 'setUpgradeNow';

const recommendedPlanEnabled = true;

const HIGHER_PLANS_TO_EXCLUDE = [...AL_UFUQ_SKU_TO_REMOVE];

const Plan: FunctionComponent<types.cards.IPlanCardProps> = ({
	params: {
		isLogged,
		productAllSKU,
		selectedFilters,
		getPlanType,
		pricesWithVat,
		setInstallmentPeriod = () => {},
		isUpfront,
		setIsUpfront,
		setUserPersonalData,
		installmentPeriod,
		isHbbInstallmentsOffer,
		accessoryWithPlan,
		mobileDeviceWithPlan,
		isTabletWithHBBWFBBPlan,
		isAccessoryWithHBBWFBBPlan,
		isMobileDevice,
		setSelectePlanId,
		setIsBaqati,
		isCommitmentBaqatiConfig,
		isCreditRatingConfig,
		errorCodeForInstallmentAmount,
	},
}): JSX.Element => {
	const [accounts, setAccounts] = useState<IAccounts>();
	const [selectedAccount, setSelectedAccount] = useState<IAccount>();
	const [dropdownItems, setDropdownItems] = useState<IDropdownItem[]>([]);
	const [selectedDropdownItem, setSelectedDropdownItem] = useState<IDropdownItem | null>(null);
	const [recommendedPlan, setRecommendedPlan] = useState<ISinglePackage>();
	const [defaultRecommendedPlan, setDefaultRecommendedPlan] = useState<ISinglePackage>();
	const [defaultWasSet, setDefaultWasSet] = useState<boolean>(false);
	const [planRecommendedByApi, setPlanRecommendedByApi] = useState<boolean>(false);
	const [defaultSelectedPlanIndex, setDefaultSelectedPlanIndex] = useState<number>();
	const [customerProducts, setCustomerProducts] = useState<ICustomerProducts>();
	const [plansToSubscribeValidated, setPlansToSubscribeValidated] = useState<ISinglePackage[]>();
	const [selectedPlan, setSelectedPlan] = useState<ISinglePackage>();
	const [validatedOffersLoading, setValidatedOffersLoading] = useState<boolean>(false);
	const [customerProductsProcessing, setCustomerProductsProcessing] = useState<boolean>(false);
	const [twoMonthInstallmentProcessing, setTwoMonthInstallmentProcessing] = useState<boolean>(true);
	const [higherPlanLoading, setHigherPlanIsLoading] = useState<boolean>(false);
	const [upgradeNow, setUpgradeNow] = useState<boolean>(false);
	const [oldUserActivePlanToCompare, setOldUserActivePlanToCompare] = useState<ISinglePackage>();
	const [blockUpgradeTo5GAnd4G5G, setBlockUpgradeTo5GAnd4G5G] = useState<boolean>(false);
	const [customerInfoTelesalesContext, setCustomerInfoTelesalesContext] = useState<ITelesalesContext>();
	const [period12, setPeriod12] = useState<boolean>(false);
	const [period24, setPeriod24] = useState<boolean>(false);
	const [smartVoucherErrorInformation, setSmartVoucherErrorInformation] = useState<string>(
		'product.details.plan.edit.voucher.warning'
	);

	const [selectedCommitmentPeriod, setSelectedCommitmentPeriod] = useState<string | undefined>();
	const [commitmentPeriods, setCommitmentPeriods] = useState<ISingleSegmentProps<string>[]>([]);
	const [isGreenRatingUser, setIsGreenRatingUser] = useState<boolean>(false);
	const { screen } = useScreen();

	const { translate } = useTranslate();
	const dispatch = useDispatch();

	const SKUDetails = useSelector((state: types.redux.IState) => state.selectedItems.details as IProductByIdItem);
	const wrongDeviceChoosen = useSelector((state: types.redux.IState) => state.selectedItems.wrongDeviceChoosen);
	const inStock = useSelector((state: types.redux.IState) => state.selectedItems.inStock);
	const planType = useSelector((state: types.redux.IState) => state.selectedItems.planType as PlanType);
	const userHaveLegacyPlan = useSelector((state: types.redux.IState) => state.selectedItems.userHaveLegacyPlan);
	const cantBeUpgradedPlanId = useSelector((state: types.redux.IState) => state.selectedItems.cantBeUpgradedPlanId);
	const allowedAccountCategories = useSelector((state: types.redux.IState) => state.settings.config.accountCategory);
	const telemarketer = useSelector((state: types.redux.IState) => state.api.authentication.telemarketer);
	const userIsCorporate = useSelector((state: types.redux.IState) => state.selectedItems.isCorporateUser);
	const isBaqatiPlan = useSelector((state: types.redux.IState) => state.selectedItems.isBaqatiPlan);
	const isHBB = useSelector((state: types.redux.IState) => state.selectedItems.isHBB);
	const isWFBB4G = useSelector((state: types.redux.IState) => state.selectedItems.isWFBB4G);
	const isWFBB5G = useSelector((state: types.redux.IState) => state.selectedItems.isWFBB5G);
	const isB2b = useSelector((state: types.redux.IState) => state.api.authentication.isB2b);
	const isB2b2c = useSelector((state: types.redux.IState) => state.api.authentication.isB2b2c);
	const isMasterUser = useSelector((state: types.redux.IState) => state.selectedItems.isMasterUser);
	const isNewPCTSmartphones = useSelector((state: types.redux.IState) => state.selectedItems.isNewPCTSmartphones);
	const telesalesSelectedPhoneNumber = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.telesalesInformation?.phoneNumber
	);
	const remoteFeatureConfig = useSelector(remoteConfigSelector);
	const DISABLE_NGN_24_MONTHS = isNGN24Months(remoteFeatureConfig);
	const DISABLE_NGN_24_MONTHS_SMARTPHONES = isNGN24MonthsSmartphones(remoteFeatureConfig);
	const DISABLE_NGN_24_MONTHS_NO_SMARTPHONES = isNGN24MonthsNoSmartphones(remoteFeatureConfig);
	const DISABLE_NGN_24_MONTHS_HBB = isNGN24MonthsHBB(remoteFeatureConfig);
	const DISABLE_NGN_24_MONTHS_WFBB = isNGN24MonthsWFBB(remoteFeatureConfig);
	const DISABLE_NEW_PCT_LAUNCH = isNewPCTLaunch(remoteFeatureConfig);
	const { isBuyNowPayLaterFlow } = useBuyNowPayLater();
	const [inputFiltering, setInputFiltering] = useState('');
	// isBNPLFailureLog is a feature flag for loging the creditrating and nationality failure in case of BNPL
	const isBNPLFailureLog = isBNPLCreditFailure(remoteFeatureConfig);

	const currentPlan = customerProducts?.activeProduct;
	const activeProvisioning = customerProducts?.activeProvisioning;
	const activePlanCommitment = customerProducts?.activePlanCommitment;
	const currentPlanIsHBB = customerProducts?.isHBB;
	const userHaveActive4G5GPlan = customerProducts?.userHaveActive4G5GPlan;
	const currentPlanIsWFBB5G = !!customerProducts?.isWFBB5G && ENABLE_4G5G;
	const currentPlanIsWFBB4G = !!customerProducts?.isWFBB4G && ENABLE_4G5G;
	const currentPlanIsHBBOrWFBB = currentPlanIsHBB || currentPlanIsWFBB5G || currentPlanIsWFBB4G;
	const isAlUfuq = !!currentPlan?.commerceAttributes?.alUfuq;
	const isBaqati =
		selectedAccount?.accountType === AccountType.MOBILE && selectedAccount?.billingType === BillingType.POSTPAID;
	const isHayyak =
		selectedAccount?.accountType === AccountType.MOBILE && selectedAccount?.billingType === BillingType.PREPAID;
	const profileHasMultiAccount = accounts && accounts.accounts.length > 1;
	const hasCommitment = !!currentPlan?.commerceAttributes?.duration;
	const selectedPlanIsHigherThanCurrent =
		(selectedPlan?.hierarchyIndex || 0) > (currentPlan?.commerceAttributes?.hierarchyIndex || 0);
	const properPlan = selectedPlanIsHigherThanCurrent
		? selectedPlan
		: upgradeNow
		? recommendedPlan
		: defaultRecommendedPlan;
	const isHbbAndCantBuyPlan = planType === PlanType.WITH_PLAN && currentPlanIsHBB && userHaveLegacyPlan;
	const skuNotFound = planType === PlanType.WITH_PLAN && !SKUDetails && inStock;
	const properPlanNotFound =
		planType === PlanType.WITH_PLAN &&
		!properPlan &&
		(!!selectedDropdownItem || !profileHasMultiAccount) &&
		isLogged;
	const multiAccountNumberNotChoosen = !selectedDropdownItem && profileHasMultiAccount;
	const showTwoMonthWarning =
		!!customerProducts?.oldDeviceCommitmentId &&
		!customerProducts?.oldDeviceCommitmentCanBeExtended &&
		!accessoryWithPlan &&
		!mobileDeviceWithPlan &&
		!isTabletWithHBBWFBBPlan &&
		!isAccessoryWithHBBWFBBPlan;

	const userIsBusiness = useMemo(() => {
		if (!!allowedAccountCategories && !!selectedAccount && planType === PlanType.WITH_PLAN) {
			return !allowedAccountCategories?.includes(selectedAccount.accountCategory || '');
		}
		return false;
	}, [selectedAccount, allowedAccountCategories]);

	const showTwoDeviceAccessoriesWarning =
		planType === PlanType.WITH_PLAN &&
		!!customerProducts?.activeDeviceAccessoriesInstallmentId &&
		(accessoryWithPlan || isAccessoryWithHBBWFBBPlan);
	const showTwoMobileDeviceWarning =
		planType === PlanType.WITH_PLAN &&
		!!customerProducts?.activeMobileDeviceInstallmentId &&
		(mobileDeviceWithPlan || isTabletWithHBBWFBBPlan);

	const isWithPlan: boolean = useMemo(() => {
		return !!(isB2b && !isMasterUser && isBaqatiPlan && isB2b && planType === PlanType.WITH_PLAN);
	}, [isBaqatiPlan, isB2b, isMasterUser, isB2b, planType]);

	const showWarning =
		(defaultWasSet &&
			(!userIsCorporate || (userIsCorporate && !commitmentPeriods?.length)) &&
			((properPlanNotFound && !isHbbInstallmentsOffer) ||
				wrongDeviceChoosen ||
				isHbbAndCantBuyPlan ||
				skuNotFound ||
				showTwoMonthWarning ||
				userHaveLegacyPlan ||
				userIsBusiness) &&
			!multiAccountNumberNotChoosen &&
			!higherPlanLoading &&
			planType === PlanType.WITH_PLAN) ||
		blockUpgradeTo5GAnd4G5G ||
		showTwoDeviceAccessoriesWarning ||
		showTwoMobileDeviceWarning ||
		isWithPlan;
	if (showWarning) {
		TagManager.dataLayer({
			dataLayer: {
				event: 'GTM Logging',
				component: 'Plan',
				customerProducts: !!customerProducts,
				activeDeviceAccessoriesInstallmentId: customerProducts?.activeDeviceAccessoriesInstallmentId,
				oldDeviceCommitmentId: customerProducts?.oldDeviceCommitmentId,
				oldDeviceCommitmentCanBeExtended: customerProducts?.oldDeviceCommitmentCanBeExtended,
				activeMobileDeviceInstallmentId: customerProducts?.activeMobileDeviceInstallmentId,
				planType,
				selectedPlan: selectedPlan?.partNumber,
				recommendedPlan: recommendedPlan?.partNumber,
				defaultRecommendedPlan: defaultRecommendedPlan?.partNumber,
				selectedPlanIsHigherThanCurrent,
				upgradeNow,
				accessoryWithPlan,
				mobileDeviceWithPlan,
				allowedAccountCategories: allowedAccountCategories?.toString(),
				selectedAccount: !!selectedAccount,
				accountId: selectedAccount?.id,
				selectedAccountId: selectedAccount?.accountId,
				blockUpgradeTo5GAnd4G5G,
				userId: getUserId(),
			},
		});
	}
	const wrongDeviceWithPlan =
		!!(currentPlan?.group === TARIFF_PACKAGE) &&
		!!isHbbInstallmentsOffer &&
		!!(planType === PlanType.WITH_PLAN) &&
		(!mobileDeviceWithPlan || !isTabletWithHBBWFBBPlan || !isAccessoryWithHBBWFBBPlan);
	const properPlanExtracted = {
		name: properPlan?.shortDescription,
		validity: translate('plan.contract.duration', String(properPlan?.duration ?? '')),
		price: String(properPlan?.price ?? ''),
		internet:
			properPlan?.planDetails?.includedData?.itemLabel === 'Unlimited'
				? 'Unlimited'
				: properPlan?.planDetails?.includedData?.itemValue,
		minutes:
			properPlan?.planDetails?.includedMinutes?.itemLabel === 'Unlimited'
				? 'Unlimited'
				: properPlan?.planDetails?.includedMinutes?.itemValue === '-'
				? 'Unlimited'
				: properPlan?.planDetails?.includedMinutes?.itemValue,
		expireDate: moment().add(properPlan?.duration, 'M').format('DD.MM.YYYY'),
		promotion: {
			promotion: !!SKUDetails?.priceDiscount,
			price: SKUDetails?.priceDiscount,
		},
		vat: Number(properPlan?.price || 0) + Number(properPlan?.vatValue || 0),
		vatPercent: Number(properPlan?.vatPercent || 0),
		hasCommitment: !!properPlan?.duration,
		isHbb: properPlan?.planType === 'HBB',
	};

	const { priceDifference, internetDifference, speedDifference } = useMemo(() => {
		let priceDifference = '0';
		let internetDifference = '0';
		let speedDifference = '0';
		if (recommendedPlan && oldUserActivePlanToCompare) {
			priceDifference = String(
				Number(recommendedPlan.price || 0) - Number(oldUserActivePlanToCompare.price || 0)
			);
			internetDifference =
				recommendedPlan.planDetails?.includedData?.itemUnit === 'Unlimited'
					? 'Unlimited'
					: String(
							Number(recommendedPlan.planDetails?.includedData?.itemValue || 0) -
								Number(oldUserActivePlanToCompare.planDetails?.includedData?.itemValue || 0)
					  );
			speedDifference =
				recommendedPlan.planDetails?.downloadSpeed?.itemUnit === 'Unlimited'
					? 'Unlimited'
					: String(
							Number(recommendedPlan.planDetails?.downloadSpeed?.itemValue || 0) *
								(recommendedPlan.planDetails?.downloadSpeed?.itemUnit === 'Gbps' ? 1024 : 1) -
								Number(oldUserActivePlanToCompare.planDetails?.downloadSpeed?.itemValue || 0) *
									(oldUserActivePlanToCompare.planDetails?.downloadSpeed?.itemUnit === 'Gbps'
										? 1024
										: 1)
					  );
		}
		return { priceDifference, internetDifference, speedDifference };
	}, [recommendedPlan, oldUserActivePlanToCompare]);

	useEffect(() => {
		dispatch(addBusyIndicator(GET_ACCOUNT_INFO_BI));
		AccountsService.getCustomerAccountOperation({}).subscribe(
			(res) => {
				isBNPLFailureLog && setUserPersonalData && setUserPersonalData(res);

				if (res?.creditRating === CreditRating.GREEN) {
					setIsGreenRatingUser(true);
				}
				dispatch(setPersonalData({ creditRating: res?.creditRating }));
				dispatch(removeBusyIndicator(GET_ACCOUNT_INFO_BI));
			},
			() => {
				dispatch(removeBusyIndicator(GET_ACCOUNT_INFO_BI));
			}
		);
	}, []);

	useEffect(() => {
		let subscription: Subscription | undefined;
		if (isLogged) {
			subscription = ProfileService.getUserProfile().subscribe(
				() => {},
				() => {}
			);
		}
		return () => {
			subscription?.unsubscribe();
		};
	}, [isLogged]);

	useEffect(() => {
		dispatch(addBusyIndicator(GET_ACCOUNT_INFO_BI));
		AccountsService.getCustomerAccountOperation({}).subscribe(
			(res) => {
				isBNPLFailureLog && setUserPersonalData && setUserPersonalData(res);

				if (res?.creditRating === CreditRating.GREEN) {
					setIsGreenRatingUser(true);
				}
				dispatch(setPersonalData({ creditRating: res?.creditRating }));
				dispatch(removeBusyIndicator(GET_ACCOUNT_INFO_BI));
			},
			() => {
				dispatch(removeBusyIndicator(GET_ACCOUNT_INFO_BI));
			}
		);
	}, []);

	useEffect(() => {
		if (!telemarketer) {
			dispatch(addBusyIndicator(GET_ACCOUNT_INFO_BI));
			AccountsService.getCustomerAccountOperation({}).subscribe(
				(res) => {
					isBNPLFailureLog && setUserPersonalData && setUserPersonalData(res);
					if (currentPlanIsWFBB4G || userHaveActive4G5GPlan) {
						const userHave5GRouter = !!res?.wfbbRouter5GType;
						setBlockUpgradeTo5GAnd4G5G(!userHave5GRouter);
					}
					dispatch(setPersonalData({ nationality: res?.nationality, creditRating: res?.creditRating }));
					dispatch(removeBusyIndicator(GET_ACCOUNT_INFO_BI));
				},
				() => {
					dispatch(removeBusyIndicator(GET_ACCOUNT_INFO_BI));
				}
			);
		}
	}, [userHaveActive4G5GPlan, currentPlanIsWFBB4G, telemarketer]);

	useEffect(() => {
		if (telemarketer && !!telesalesSelectedPhoneNumber && planType === PlanType.WITH_PLAN) {
			dispatch(addBusyIndicator(GET_TELESALES_ACCOUNT_INFO_BI));
			AccountsService.getCustomerAccountOperation(
				telemarketer ? { forceNumber: telesalesSelectedPhoneNumber } : {}
			).subscribe(
				(res) => {
					const data = {
						telesalesCustomerBillingType: res?.telesalesCustomerBillingType,
						telesalesCustomerAccountType: res?.telesalesCustomerAccountType,
						telesalesCustomerAccountCategory: res?.telesalesCustomerAccountCategory,
					};
					setCustomerInfoTelesalesContext(data);
					const userHave5GRouter = !!res?.wfbbRouter5GType;
					if (currentPlanIsWFBB4G || userHaveActive4G5GPlan) {
						setBlockUpgradeTo5GAnd4G5G(!userHave5GRouter);
					}
					dispatch(removeBusyIndicator(GET_TELESALES_ACCOUNT_INFO_BI));
				},
				() => {
					dispatch(removeBusyIndicator(GET_TELESALES_ACCOUNT_INFO_BI));
				}
			);
		}
	}, [telesalesSelectedPhoneNumber, userHaveActive4G5GPlan, currentPlanIsWFBB4G, planType, telemarketer]);

	useEffect(() => {
		getPlanType && getPlanType(planType || PlanType.DEVICE_ONLY);
		if (planType === PlanType.DEVICE_ONLY) {
			dispatch(
				setSelectedItems({
					userHaveLegacyPlan: false,
					voucherWarning: undefined,
					...clearSelectedCorporateItem(),
				})
			);
			dispatch(removeBusyIndicator(SELECT_ACCOUNT));
			dispatch(removeBusyIndicator(GET_CUSTOMER_PRODUCTS));
			dispatch(removeBusyIndicator(GET_SMART_VOUCHER));
			dispatch(removeBusyIndicator(GET_RECOMMENDED_PLAN));
			setCommitmentPeriods([]);
		}
		if (planType === PlanType.WITH_PLAN) {
			console.log(
				`userIsBusiness: ${
					!!allowedAccountCategories && !!selectedAccount && planType === PlanType.WITH_PLAN
				}, allowedAccountCategories: ${allowedAccountCategories}, selectedAccount: 
				${JSON.stringify(selectedAccount)}, isTelemarketer: ${telemarketer}, 
				telesalesSelectedPhoneNumber: ${telesalesSelectedPhoneNumber}, customerInfoTelesalesContext: ${JSON.stringify(
					customerInfoTelesalesContext
				)}`
			);
			dispatch(addBusyIndicator(SELECT_ACCOUNT));
		}
	}, [planType]);

	useEffect(() => {
		if (wrongDeviceWithPlan) {
			dispatch(
				setSelectedItems({
					wrongDeviceChoosen: true,
				})
			);
		}
	}, [planType, currentPlan, isHbbInstallmentsOffer]);

	const getPackageGroup = () => {
		if (currentPlanIsHBB) {
			return 'HBB';
		} else if (currentPlanIsWFBB5G || currentPlanIsWFBB4G) {
			return 'WFBB';
		} else {
			return 'POSTPAID';
		}
	};

	useEffect(() => {
		// check if user can purchase new plan
		if (
			customerProducts?.activeProduct?.productId &&
			!customerProductsProcessing &&
			planType === PlanType.WITH_PLAN
		) {
			dispatch(addBusyIndicator(CHECK_IF_CAN_GET_NEW_PLAN));
			PackagesService.getPackagesByGroupAndType('PLAN', getPackageGroup()).subscribe(
				(response) => {
					const available = response.packages?.find((plan) => {
						return plan.partNumberRaw === customerProducts?.activeProduct.productId;
					});
					if (selectedDropdownItem || !profileHasMultiAccount) {
						setOldUserActivePlanToCompare(available);
						dispatch(setSelectedItems({ userHaveLegacyPlan: !available }));
					}
					if (available && available?.cantBeUpgraded) {
						dispatch(
							setSelectedItems({
								cantBeUpgradedPlanId: available.partNumberRaw,
							})
						);
					}
					dispatch(removeBusyIndicator(CHECK_IF_CAN_GET_NEW_PLAN));
				},
				(e) => {
					if (selectedDropdownItem || !profileHasMultiAccount) {
						dispatch(setSelectedItems({ userHaveLegacyPlan: true }));
					}
					dispatch(removeBusyIndicator(CHECK_IF_CAN_GET_NEW_PLAN));
				}
			);
		} else if (!customerProducts?.activeProduct?.productId && !customerProductsProcessing) {
			dispatch(setSelectedItems({ userHaveLegacyPlan: true }));
		}
	}, [customerProducts, accounts, customerProductsProcessing, planType]);

	useEffect(() => {
		// set infinite loading if user has blocked number and can't subscribe to new plan
		if (
			profileHasMultiAccount &&
			!selectedDropdownItem &&
			isHbbInstallmentsOffer &&
			!showTwoMonthWarning &&
			planType === PlanType.WITH_PLAN &&
			!userIsBusiness
		) {
			if (!selectedDropdownItem && isGreenRatingUser) {
				dispatch(addBusyIndicator(NOT_CHOSEN_NUMBER));
			} else {
				dispatch(addBusyIndicator(INFINITE_LOADING));
			}
		} else if (
			!properPlan &&
			planType === PlanType.WITH_PLAN &&
			!isHbbInstallmentsOffer &&
			!showTwoMonthWarning &&
			!userIsBusiness
		) {
			if (!selectedDropdownItem && isGreenRatingUser) {
				dispatch(addBusyIndicator(NOT_CHOSEN_NUMBER));
			} else {
				dispatch(addBusyIndicator(INFINITE_LOADING));
			}
		} else {
			dispatch(removeBusyIndicator(INFINITE_LOADING));
			dispatch(removeBusyIndicator(NOT_CHOSEN_NUMBER));
		}
	}, [
		selectedPlan,
		recommendedPlan,
		planType,
		profileHasMultiAccount,
		isHbbInstallmentsOffer,
		selectedDropdownItem,
		defaultRecommendedPlan,
		upgradeNow,
		!twoMonthInstallmentProcessing,
		userIsBusiness,
	]);

	useEffect(() => {
		dispatch(
			setSelectedItems({
				planType,
				showWarning,
				profileHasMultiAccount: !!profileHasMultiAccount,
				showTwoMonthWarning,
			})
		);
	}, [
		planType,
		selectedPlan,
		recommendedPlan,
		profileHasMultiAccount,
		selectedDropdownItem,
		accounts,
		wrongDeviceChoosen,
		inStock,
		SKUDetails,
		showWarning,
		currentPlan,
		isHbbInstallmentsOffer,
		showTwoMonthWarning,
	]);

	useEffect(() => {
		if (planType === PlanType.DEVICE_ONLY) {
			dispatch(setSelectedItems({ wrongDeviceChoosen: false }));
		}
	}, [planType, accounts, selectedDropdownItem]);

	useEffect(() => {
		if (!wrongDeviceWithPlan) {
			dispatch(setSelectedItems({ wrongDeviceChoosen: false }));
		}
	}, [selectedFilters]);

	useEffect(() => {
		// add or delete selected plan to store, which can be displayed on cart
		if (planType === PlanType.WITH_PLAN && (properPlan || isHbbInstallmentsOffer)) {
			dispatch(setSelectedItems({ plan: properPlan }));
		} else if (planType === PlanType.DEVICE_ONLY) {
			dispatch(
				setSelectedItems({
					plan: undefined,
					...clearSelectedCorporateItem(),
				})
			);
		}
	}, [
		planType,
		selectedPlan,
		currentPlan,
		selectedAccount,
		recommendedPlan,
		defaultRecommendedPlan,
		isHbbInstallmentsOffer,
		upgradeNow,
	]);

	useEffect(() => {
		if (planType === PlanType.WITH_PLAN) {
			setSelectedCommitmentPeriod(undefined);
			setCommitmentPeriods([]);
			dispatch(
				setSelectedItems({
					selectedAccount,
					...clearSelectedCorporateItem(false),
				})
			);
			isB2b && setPlanType(PlanType.WITH_PLAN);
		} else if (isBuyNowPayLaterFlow) {
			dispatch(
				setSelectedItems({
					selectedAccount,
					...clearSelectedCorporateItem(false),
				})
			);
		} else {
			dispatch(setSelectedItems({ selectedAccount: undefined }));
		}
		dispatch(removeBusyIndicator(SELECT_ACCOUNT));
	}, [selectedAccount, planType, isBuyNowPayLaterFlow]);

	useEffect(() => {
		// get and set user numbers assigned to his account
		if (isLogged) {
			dispatch(addBusyIndicator(GET_ACCOUNT_FOR_LOGGED_USER));
			AccountsService.getAccountsForLoggedUser().subscribe(
				(response) => {
					setAccounts(response);
					const dropdownItems: IDropdownItem[] = [];
					response.accounts.forEach((account) => {
						dropdownItems.push({
							prefix: OMANTEL_PHONE_NUMBER_PREFIX,
							text: account.accountNum ?? '',
							id: account.accountId?.toString() ?? '',
							disabled:
								account.billingType === 'PREPAID' || ['FIX', ''].includes(account.accountType || ''),
						});
					});
					setDropdownItems(dropdownItems);
					dispatch(removeBusyIndicator(GET_ACCOUNT_FOR_LOGGED_USER));
				},
				() => {
					dispatch(removeBusyIndicator(GET_ACCOUNT_FOR_LOGGED_USER));
				}
			);
		}
	}, [isLogged]);

	useEffect(() => {
		let subscription: Subscription | undefined;
		if (
			isLogged &&
			selectedAccount?.accountNum &&
			selectedAccount.billingType &&
			!recommendedPlan &&
			!currentPlanIsHBB &&
			!twoMonthInstallmentProcessing &&
			!showTwoMonthWarning &&
			recommendedPlanEnabled
		) {
			if (userIsCorporate && planType === PlanType.WITH_PLAN) {
			} else {
				dispatch(addBusyIndicator(GET_RECOMMENDED_PLAN));
				subscription = PackagesService.getRecommendedAndValidatedPlan({
					phoneNumber: selectedAccount.accountNum,
					billingType: selectedAccount.billingType,
					currentPlan,
					profileHasMultiAccount: !!profileHasMultiAccount,
					selectedDropdownItem,
					selectedAccount,
					comParamId: SKUDetails?.comParamId || '',
					isHBB: !!currentPlanIsHBB,
					currentPlanIsWFBB5G,
					currentPlanIsWFBB4G,
					skusName: SKUDetails?.partNumber || '',
					validate: VALIDATE_OFFERS,
					oldDeviceCommitment: customerProducts?.oldDeviceCommitmentId,
				}).subscribe(
					(r) => {
						if (r?.recommendedPlan) {
							setRecommendedPlan(r.recommendedPlan);
							setPlanRecommendedByApi(true);
						}
						dispatch(removeBusyIndicator(GET_RECOMMENDED_PLAN));
					},
					() => {
						dispatch(removeBusyIndicator(GET_RECOMMENDED_PLAN));
					}
				);
			}
		} else {
			dispatch(removeBusyIndicator(GET_SMART_VOUCHER));
		}
		return () => {
			subscription?.unsubscribe();
		};
	}, [isLogged, selectedAccount, currentPlan, SKUDetails, twoMonthInstallmentProcessing, userIsCorporate, planType]);

	useEffect(() => {
		if (commitmentPeriods?.length) {
			selectCommitmentPeriod(commitmentPeriods?.[0]?.value);
		}
	}, [commitmentPeriods]);

	useEffect(() => {
		if (
			isLogged &&
			selectedAccount?.accountNum &&
			selectedAccount?.billingType &&
			selectedAccount?.accountType &&
			!telemarketer
		) {
			getCustomerProducts({
				phoneNumber: selectedAccount.accountNum,
				billingType: selectedAccount.billingType,
				accountType: selectedAccount.accountType,
			});
		}
	}, [isLogged, selectedAccount]);

	useEffect(() => {
		if (
			isLogged &&
			customerInfoTelesalesContext?.telesalesCustomerAccountType &&
			customerInfoTelesalesContext.telesalesCustomerBillingType &&
			telesalesSelectedPhoneNumber &&
			telemarketer
		) {
			getCustomerProducts({
				phoneNumber: telesalesSelectedPhoneNumber,
				billingType: customerInfoTelesalesContext?.telesalesCustomerBillingType,
				accountType: customerInfoTelesalesContext?.telesalesCustomerAccountType,
			});
		}
	}, [isLogged, customerInfoTelesalesContext, telesalesSelectedPhoneNumber, telemarketer, planType]);

	const clearSelectedCorporateItem = (clearAccount = true) => {
		const data: any = {
			selectedCommitmentPeriod: undefined,
			selectedPaymentMethod: undefined,
			selectedInstallment: undefined,
			calculatedProduct: undefined,
		};
		if (clearAccount) {
			data.isCorporateUser = false;
			data.isUserReady = false;
		}
		return data;
	};

	const dispatchCustomerProductsLoaders = () => {
		if (!defaultWasSet) {
			setTimeout(() => {
				setDefaultWasSet(true);
				dispatch(removeBusyIndicator(GET_CUSTOMER_PRODUCTS));
				dispatch(removeBusyIndicator(GET_SMART_VOUCHER));
			}, TIMEOUT_1000);
		} else {
			dispatch(removeBusyIndicator(GET_CUSTOMER_PRODUCTS));
			dispatch(removeBusyIndicator(GET_SMART_VOUCHER));
		}
		setTwoMonthInstallmentProcessing(false);
		setCustomerProductsProcessing(false);
	};

	const getCustomerProducts = ({
		phoneNumber,
		billingType,
		accountType,
	}: {
		phoneNumber: string;
		billingType: BillingType;
		accountType: AccountType;
	}) => {
		dispatch(addBusyIndicator(GET_CUSTOMER_PRODUCTS));
		dispatch(addBusyIndicator(GET_SMART_VOUCHER));
		setCustomerProductsProcessing(true);
		// setTwoMonthInstallmentProcessing(true);
		PackagesService.getCustomerProducts({
			phoneNumber,
			billingType,
			accountType,
			productsExtendedInfo: true,
			isBaqati,
		}).subscribe(
			(response) => {
				setCustomerProducts(response);
				const isBaqati = response.isBaquati;
				setIsBaqati(response?.isBaquati ?? false);

				const isB2b2cUser = B2B2C_CATEGORIES.includes(selectedAccount?.accountCategory || '');
				const isB2bUser = isB2b2c ? false : isB2b;
				let isCorporateUser = isB2b || isB2b2c;

				if (DISABLE_EBU_SINGLE_PURCHASE) {
					isCorporateUser = false;
				} else {
					if (
						(isB2b2cUser && !DISABLE_EBU_SINGLE_PURCHASE_B2B2C_BAQATI) ||
						(isB2bUser && !DISABLE_EBU_SINGLE_PURCHASE_B2B_BAQATI)
					) {
						isCorporateUser = !isBaqati;
					}
				}

				const cantPlanChange = !!(
					((!isMobileDevice && !isBaqati) || !selectedAccount || (isB2bUser && !isMasterUser && isBaqati)) &&
					isB2bUser
				);
				dispatch(
					setB2bUser(!!isB2bUser && !DISABLE_EBU_SINGLE_PURCHASE && !DISABLE_EBU_SINGLE_PURCHASE_B2B_BAQATI)
				);
				dispatch(
					setB2b2cUser(
						isB2b2cUser && !DISABLE_EBU_SINGLE_PURCHASE && !DISABLE_EBU_SINGLE_PURCHASE_B2B2C_BAQATI
					)
				);
				dispatch(
					setSelectedItems({
						currentPlan: response.activeProduct,
						activeProvisioning: response.activeProvisioning,
						activePlanCommitment: response.activePlanCommitment,
						isHBB: response.isHBB,
						isWFBB5G: response.isWFBB5G,
						isWFBB4G: response.isWFBB4G,
						oldDeviceCommitmentId: response.oldDeviceCommitmentId,
						oldDeviceCommitmentCanBeExtended: response.oldDeviceCommitmentCanBeExtended,
						sendDeviceCommitment: response.sendDeviceCommitment,
						activeDeviceAccessoriesInstallmentId: response.activeDeviceAccessoriesInstallmentId,
						activeMobileDeviceInstallmentId: response.activeMobileDeviceInstallmentId,
						isBaqatiPlan: isBaqati,
						isCorporateUser: isCorporateUser && !DISABLE_EBU_SINGLE_PURCHASE,
						voucherWarning: undefined,
						showWarning: cantPlanChange,
					})
				);

				if (isCorporateUser) {
					dispatch(addBusyIndicator(GET_SMART_VOUCHER));
					ProductsService.getSmartVoucher(String(selectedAccount?.id), phoneNumber).subscribe(
						(res) => {
							const vouchers = (res.vouchers || [])
								.sort((v1, v2) => (v1.commitmentDuration > v2.commitmentDuration ? 1 : -1))
								.map((voucher) => {
									return {
										name: translate('plan.duration', voucher?.commitmentDuration || 0),
										value: String(voucher?.commitmentDuration),
										data: voucher,
										disabled: res?.vouchers?.length <= 1,
									};
								});
							setCommitmentPeriods(vouchers);
							dispatch(removeBusyIndicator(GET_SMART_VOUCHER));
							dispatch(removeBusyIndicator(GET_RECOMMENDED_PLAN));
							dispatch(removeBusyIndicator(SHOW_SUMMARY_INDICATOR));
							dispatch(removeBusyIndicator(GET_CUSTOMER_PRODUCTS));
							if (vouchers?.length) {
								dispatch(setSelectedItems({ voucherWarning: undefined }));
							} else {
								dispatch(
									setSelectedItems({
										voucherWarning: translate('product.details.plan.edit.voucher.warning'),
									})
								);
							}
						},
						({ response }) => {
							const code: string = response?.errorDesc || '';
							let errorDescription: string = '';
							if (code.includes('INVALID_ACCOUNT_CATEGORY')) {
								errorDescription = 'product.details.plan.edit.voucher.warning.invalid.account.category';
							} else if (code.includes('CLOSED_BALANCE')) {
								errorDescription = 'product.details.plan.edit.voucher.warning.closed.balance';
							} else if (code.includes('NOT_CONFIGURED')) {
								errorDescription = 'product.details.plan.edit.voucher.warning.not.configured';
							} else if (code.includes('DEVICE_COMMITMENT_EXISTS')) {
								errorDescription =
									'product.details.plan.edit.voucher.warning.device.commitments.exists';
							} else if (code.includes('NOT_ACTIVE')) {
								errorDescription = 'product.details.plan.edit.voucher.warning.not.active';
							} else {
								errorDescription = 'product.details.plan.edit.voucher.warning';
							}
							setSmartVoucherErrorInformation(errorDescription);
							dispatch(setSelectedItems({ voucherWarning: translate(errorDescription) }));
							dispatch(removeBusyIndicator(GET_SMART_VOUCHER));
							dispatch(removeBusyIndicator(GET_CUSTOMER_PRODUCTS));
							dispatch(removeBusyIndicator(GET_RECOMMENDED_PLAN));
							dispatch(removeBusyIndicator(SHOW_SUMMARY_INDICATOR));
							dispatch(removeBusyIndicator(INFINITE_LOADING));
							dispatch(removeBusyIndicator(NOT_CHOSEN_NUMBER));
						}
					);
				}

				dispatchCustomerProductsLoaders();
			},
			(e) => {
				dispatchCustomerProductsLoaders();
			}
		);
	};

	useEffect(() => {
		// set selected account
		if (!telemarketer) {
			if (accounts && selectedDropdownItem) {
				const selectedAccount = accounts.accounts.find(
					(account) => account.accountNum === selectedDropdownItem.text
				);
				setSelectedAccount(selectedAccount);
			} else if (!selectedDropdownItem && accounts && !profileHasMultiAccount) {
				setSelectedAccount(accounts?.accounts[0]);
			}
		}
	}, [accounts, selectedDropdownItem, customerInfoTelesalesContext, telemarketer]);

	useEffect(() => {
		// set selected account telesales context
		if (telemarketer && telesalesSelectedPhoneNumber && customerInfoTelesalesContext) {
			setSelectedAccount({
				accountNum: telesalesSelectedPhoneNumber,
				billingType: customerInfoTelesalesContext?.telesalesCustomerBillingType,
				accountType: customerInfoTelesalesContext?.telesalesCustomerAccountType,
				accountPlan: null,
				accountCategory: customerInfoTelesalesContext?.telesalesCustomerAccountCategory,
			});
		}
	}, [customerInfoTelesalesContext, telesalesSelectedPhoneNumber]);

	useEffect(() => {
		// reset selected plan when user select something on dropdown or logged out
		if (!isLogged) {
			setPlanType(PlanType.DEVICE_ONLY);
		}
		setSelectedPlan(undefined);
		setSelectePlanId('');
		setRecommendedPlan(undefined);
		setPlanRecommendedByApi(false);
		setDefaultSelectedPlanIndex(undefined);
		setPlansToSubscribeValidated(undefined);
		setUpgradeNow(false);
		dispatch(setSelectedItems({ userHaveLegacyPlan: false }));
	}, [accounts, selectedDropdownItem, isLogged]);

	useEffect(() => {
		// set recommended plan from commerce if omantel api does not return any recommended plan
		if (
			!planRecommendedByApi &&
			planType === PlanType.WITH_PLAN &&
			!twoMonthInstallmentProcessing &&
			!showTwoMonthWarning
		) {
			if (currentPlan && accounts && selectedAccount) {
				const currentPlanHierarchyIndex = currentPlan?.commerceAttributes?.hierarchyIndex ?? 0;
				if (!!plansToSubscribeValidated && !recommendedPlan) {
					const recommended = plansToSubscribeValidated.find((singlePlan) => {
						return singlePlan.hierarchyIndex > currentPlanHierarchyIndex;
					});

					if (recommended) {
						setRecommendedPlan(recommended);
					} else {
						setRecommendedPlan(plansToSubscribeValidated[plansToSubscribeValidated.length - 1]);
					}
				} else {
					if (!isHbbInstallmentsOffer) {
						getValidatedHigherPlan();
					}
				}
			} else {
				if (!!plansToSubscribeValidated) {
					setRecommendedPlan(plansToSubscribeValidated[0]);
				} else {
					setRecommendedPlan(undefined);
				}
			}
		}
	}, [productAllSKU, currentPlan, cantBeUpgradedPlanId, plansToSubscribeValidated, twoMonthInstallmentProcessing]);

	useEffect(() => {
		// set default recommended plan when upgrede now wasn't clicked
		if (
			planType === PlanType.WITH_PLAN &&
			!!isLogged &&
			!!currentPlan &&
			!!selectedAccount &&
			!twoMonthInstallmentProcessing &&
			!showTwoMonthWarning &&
			productAllSKU?.length
		) {
			dispatch(addBusyIndicator(VALIDATE_OFFER));
			setValidatedOffersLoading(true);
			PackagesService.getValidatedOffers({
				currentPlan,
				activeProvisioning,
				activePlanCommitment,
				profileHasMultiAccount: !!profileHasMultiAccount,
				selectedDropdownItem,
				selectedAccount,
				productAllSKU,
				selectedFilters,
				isHBB: !!currentPlanIsHBB,
				currentPlanIsWFBB5G,
				currentPlanIsWFBB4G,
				validate: VALIDATE_OFFERS,
				oldDeviceCommitment: customerProducts?.oldDeviceCommitmentId,
				isB2b2c,
				isB2b,
				isCommitmentBaqatiConfig,
			}).subscribe(
				(validatedPlans) => {
					const currentPlanHierarchyIndex = currentPlan?.commerceAttributes?.hierarchyIndex ?? 0;
					const filteredPlans = validatedPlans.filter((singleValidatedPlan) => !!singleValidatedPlan);
					const higherPlansThanCurrent = filteredPlans.filter((singleValidatedPlan) => {
						const isHierarchyIndexHigher =
							(singleValidatedPlan?.hierarchyIndex || 0) > currentPlanHierarchyIndex;
						if (currentPlan?.commerceAttributes?.alUfuq) {
							return isHierarchyIndexHigher;
						} else {
							return (
								isHierarchyIndexHigher &&
								!HIGHER_PLANS_TO_EXCLUDE.includes(singleValidatedPlan?.partNumber || '')
							);
						}
					});

					const recommended = filteredPlans.find((singlePlan) => {
						return (singlePlan?.hierarchyIndex || 0) >= currentPlanHierarchyIndex;
					});
					setDefaultRecommendedPlan(recommended as ISinglePackage);
					setPlansToSubscribeValidated(higherPlansThanCurrent as ISinglePackage[]);
					setValidatedOffersLoading(false);
					if (!defaultWasSet) {
						setTimeout(() => {
							setDefaultWasSet(true);
							dispatch(removeBusyIndicator(VALIDATE_OFFER));
						}, TIMEOUT_1000);
					} else {
						dispatch(removeBusyIndicator(VALIDATE_OFFER));
					}
				},
				(e) => {
					setValidatedOffersLoading(false);
					if (!defaultWasSet) {
						setTimeout(() => {
							setDefaultWasSet(true);
							dispatch(removeBusyIndicator(VALIDATE_OFFER));
						}, TIMEOUT_1000);
					} else {
						dispatch(removeBusyIndicator(VALIDATE_OFFER));
					}
				}
			);
		}
	}, [currentPlan, upgradeNow, twoMonthInstallmentProcessing, productAllSKU]);

	useEffect(() => {
		if (
			planType === PlanType.WITH_PLAN &&
			!!isLogged &&
			!!currentPlan &&
			!!selectedAccount &&
			!validatedOffersLoading &&
			!twoMonthInstallmentProcessing &&
			!showTwoMonthWarning &&
			properPlan &&
			productAllSKU?.length
		) {
			dispatch(addBusyIndicator(VALIDATE_CHANGED_INSTALLMENT_SKUS));
			PackagesService.validateSelectedPlan({
				currentPlan,
				activeProvisioning,
				activePlanCommitment,
				profileHasMultiAccount: !!profileHasMultiAccount,
				selectedDropdownItem,
				selectedAccount,
				productAllSKU,
				selectedFilters,
				selectedPlan: properPlan,
				isHBB: !!currentPlanIsHBB,
				currentPlanIsWFBB5G,
				currentPlanIsWFBB4G,
				validate: VALIDATE_OFFERS,
				oldDeviceCommitment: customerProducts?.oldDeviceCommitmentId,
				isB2b2c,
				isB2b,
			}).subscribe(
				(validatedPlan) => {
					if (validatedPlan) {
						setSelectedPlan(validatedPlan || undefined);
						setSelectePlanId(
							validatedPlan?.partNumber || (currentPlan?.legacyProductId.replace('OM_', '') ?? '')
						);
					} else {
						// TODO: set information/modal that current sku is not eligible ?
						setSelectedPlan(undefined);
						setSelectePlanId('');
					}

					dispatch(removeBusyIndicator(VALIDATE_CHANGED_INSTALLMENT_SKUS));
				},
				(e) => {
					setSelectedPlan(undefined);
					setSelectePlanId('');
					dispatch(removeBusyIndicator(VALIDATE_CHANGED_INSTALLMENT_SKUS));
				}
			);
		}
	}, [
		selectedFilters.installmentPeriod,
		// twoMonthInstallmentProcessing,
		properPlan,
		SKUDetails?.partNumber,
		validatedOffersLoading,
	]);

	const getValidatedHigherPlan = () => {
		if (
			planType === PlanType.WITH_PLAN &&
			!!isLogged &&
			!!currentPlan &&
			!!selectedAccount &&
			productAllSKU?.length
		) {
			setHigherPlanIsLoading(true);
			dispatch(addBusyIndicator(GET_HIGHER_PLAN));
			PackagesService.getHigherValidatedPlan({
				currentPlan,
				activeProvisioning,
				activePlanCommitment,
				profileHasMultiAccount: !!profileHasMultiAccount,
				selectedDropdownItem,
				selectedAccount,
				productAllSKU,
				selectedFilters,
				isHBB: !!currentPlanIsHBB,
				currentPlanIsWFBB5G,
				currentPlanIsWFBB4G,
				validate: VALIDATE_OFFERS,
				cantBeUpgradedPlanId,
				oldDeviceCommitment: customerProducts?.oldDeviceCommitmentId,
				isB2b2c,
				isB2b,
			}).subscribe(
				(validatedPlan) => {
					if (validatedPlan) {
						setRecommendedPlan(validatedPlan);
					}

					if (
						currentPlanIsHBB &&
						!validatedPlan &&
						(!mobileDeviceWithPlan || !isTabletWithHBBWFBBPlan || !isAccessoryWithHBBWFBBPlan)
					) {
						dispatch(setSelectedItems({ wrongDeviceChoosen: true }));
					} else if (
						(currentPlanIsWFBB5G || currentPlanIsWFBB4G) &&
						!validatedPlan &&
						(!mobileDeviceWithPlan ||
							!isTabletWithHBBWFBBPlan ||
							!isTabletWithHBBWFBBPlan ||
							!isAccessoryWithHBBWFBBPlan)
					) {
						dispatch(setSelectedItems({ wrongDeviceChoosen: true }));
					}
					setHigherPlanIsLoading(false);
					dispatch(removeBusyIndicator(GET_HIGHER_PLAN));
				},
				(e) => {
					setHigherPlanIsLoading(false);
					dispatch(removeBusyIndicator(GET_HIGHER_PLAN));
				}
			);
		}
	};

	useEffect(() => {
		if (isCommitmentBaqatiConfig && planType === PlanType.WITH_PLAN) {
			setPeriod12(true);
			setPeriod24(true);
		} else if (productAllSKU && productAllSKU.length > 0) {
			const period12: IProductByIdItem[] = [];
			const period24: IProductByIdItem[] = [];
			const skus: IProductByIdItem[] = productAllSKU;
			skus.forEach((sku: IProductByIdItem) => {
				if (sku.planDetails.planId === SKUDetails?.planDetails.planId) {
					if (sku.installmentValue !== undefined) {
						if (sku.installmentPeriod === '12') {
							period12.push(sku);
						}
						if (sku.installmentPeriod === '24') {
							period24.push(sku);
						}
					}
				}
			});

			setPeriod12(period12.length > 0);
			setPeriod24(period24.length > 0);
		} else {
			setPeriod12(false);
			setPeriod24(false);
		}
	}, [productAllSKU, currentPlan, SKUDetails]);

	const openPlanSelectorModal = (validatedPlans?: ISinglePackage[]) => {
		if ((plansToSubscribeValidated || validatedPlans) && currentPlan?.commerceAttributes && recommendedPlan) {
			const currentPlanAttributes = {
				...currentPlan?.commerceAttributes,
				partNumberRaw: currentPlan.productId,
				isHBB: currentPlanIsHBB,
			};
			const planToCompareAttributes = selectedPlanIsHigherThanCurrent
				? selectedPlan
				: recommendedPlan || defaultRecommendedPlan;
			dispatch(
				setModal({
					data: {
						plansToCompare: [currentPlanAttributes, planToCompareAttributes],
						otherPlans: validatedPlans || plansToSubscribeValidated,
						recommendedPlan,
						productAllSKU,
						selectedFilters,
						isProductCardRoute: true,
						defaultSelectedPlanIndex,
						currentPlanIsHBB,
						pricesWithVat,
						setSelectedPlan: (selectedPlan: ISinglePackage, selectedPlanIndex: number) => {
							setSelectedPlan(selectedPlan);
							setSelectePlanId(selectedPlan?.partNumber ?? '');
							setDefaultSelectedPlanIndex(selectedPlanIndex);
						},
						setSelectedPlanInstallment: (installmentPeriod: '12' | '24') => {
							setInstallmentPeriod?.(installmentPeriod);
						},
						emmitEvent: (eventName: string, upgrade: any) => {
							if (eventName === EVENT_UPGRADE_NOW) {
								setUpgradeNow(!!upgrade);
							}
						},
						selectedProductName: SKUDetails?.name ?? '',
					},
					type: modalTypes.PLAN_SELECTOR,
				})
			);
		}
	};

	const getPlanBar = () => {
		const activeNow = moment.utc(currentPlan?.endDate, 'DD.MM.YYYY').isAfter(moment.utc(new Date()));
		if (currentPlanIsHBB) {
			return activeNow ? PlanBarType.UNLIMITED : PlanBarType.UNLIMITED_INACTIVE;
		}
		if (isAlUfuq) {
			return activeNow ? PlanBarType.BAQATI_AL_UFUQ : PlanBarType.BAQATI_AL_UFUQ_INACTIVE;
		}
		return activeNow ? PlanBarType.BAQATI : PlanBarType.BAQATI_INACTIVE;
	};

	const getSelectedPlanBar = () => {
		if (properPlan?.primaryOffer) {
			return PlanBarType.UNLIMITED;
		}
		if (properPlan?.alUfuq) {
			return PlanBarType.BAQATI_AL_UFUQ;
		}
		return PlanBarType.BAQATI;
	};

	const handleDeviceOnly = () => {
		setPlanType(PlanType.DEVICE_ONLY);
		dispatch(setSelectedItems({ wrongDeviceChoosen: false }));
	};

	const handlePlanChange = () => {
		if (planType === PlanType.WITH_PLAN && plansToSubscribeValidated?.length) {
			openPlanSelectorModal();
		}
	};

	const setPlanType = (planType: PlanType) => {
		dispatch(setSelectedItems({ planType }));
	};

	const getTranslationUpgradePlan = () => {
		const priceDifferenceNumber = Number(priceDifference);
		return translate(
			priceDifferenceNumber > 0
				? 'upgrade.plan.more.internet.for.local.gcc.more'
				: 'upgrade.plan.more.internet.for.local.gcc.less',
			recommendedPlan?.name || '',
			String(internetDifference),
			String(oldUserActivePlanToCompare?.planDetails?.downloadSpeed?.itemUnit || ''),
			String(priceDifferenceNumber > 0 ? priceDifference : Math.abs(priceDifferenceNumber))
		);
	};

	const getTranslationUpgradePlanWfbb = () => {
		const priceDifferenceNumber = Number(priceDifference);
		return translate(
			priceDifferenceNumber > 0
				? 'upgrade.plan.more.internet.for.faster.internet.more'
				: 'upgrade.plan.more.internet.for.faster.internet.less',
			recommendedPlan?.name || '',
			String(speedDifference),
			String(oldUserActivePlanToCompare?.planDetails?.downloadSpeed?.itemUnit || ''),
			String(priceDifferenceNumber > 0 ? priceDifference : Math.abs(priceDifferenceNumber))
		);
	};

	const getWarningMessageForInstallmentAmount = (errorCode: string | undefined) => {
		switch (errorCode) {
			case 'DU02':
			case 'PS05':
			case 'PS06':
			case 'PS07':
			case 'PS08':
				return translate('product.details.plan.edit.not.eligible');
			case 'PS04':
				return translate('product.details.plan.edit.no.credit.limit');
			default:
				return translate('product.details.plan.edit.warning');
		}
	};

	const isInstallmentAmountError = errorCodeForInstallmentAmount === '' ? false : true;

	const selectCommitmentPeriod = (value: string) => {
		setSelectedCommitmentPeriod(value);
		const foundData = commitmentPeriods.find((cp) => cp?.value === value);
		dispatch(
			setSelectedItems({
				selectedCommitmentPeriod: foundData?.data,
			})
		);
	};
	const bnplBannerImageUrl = getLang() === 'en' ? bnplbanner : arabicBnplBanner;

	const is24MonthsDisabled = () => {
		if (!DISABLE_NGN_24_MONTHS) {
			// 24 months installment option enabled for non green Baqati users with new PCT or old PCT
			if (!isGreenRatingUser && (isHBB || isWFBB5G || isWFBB4G)) {
				return true;
			} else return false;
			// if (
			// 	(!DISABLE_NGN_24_MONTHS_SMARTPHONES &&
			// 		(isMobileDevice || (!DISABLE_NEW_PCT_LAUNCH && isNewPCTSmartphones))) ||
			// 	(!DISABLE_NGN_24_MONTHS_NO_SMARTPHONES && !isMobileDevice) ||
			// 	(!DISABLE_NGN_24_MONTHS_HBB && currentPlanIsHBBOrWFBB) ||
			// 	(!DISABLE_NGN_24_MONTHS_WFBB && currentPlanIsHBBOrWFBB)
			// ) {
			// 	return !isCreditRatingConfig;
			// }
		} else return isCreditRatingConfig && !isGreenRatingUser;
	};

	return (
		<ConditionalRender
			show={!!inStock}
			onTrue={() => (
				<CardWrapper>
					<Row>
						<Title textTransform="uppercase" color="black54">
							{translate(!!isB2b ? 'product.details.plan' : 'product.details.plan-title')}
						</Title>
					</Row>

					<Row>
						<BusyIndicator listener={[GET_CUSTOMER_PRODUCTS]} skeleton="planType">
							<ConditionalRender
								show={!!isB2b}
								onTrue={() => (
									<SegmentSelector<PlanType>
										show={true}
										setMethod={setPlanType}
										leftDisabled={false}
										rightDisabled={!isLogged}
										leftSegmentName={translate('product.details.plan.device.only')}
										rightSegmentName={translate('product.details.plan.with.plan')}
										leftSegmentValue={PlanType.DEVICE_ONLY}
										rightSegmentValue={PlanType.WITH_PLAN}
										selectedValue={planType}
									/>
								)}
								onFalse={() => (
									<ConditionalRender
										show={isBuyNowPayLaterFlow}
										onTrue={() => (
											<InstallmentsButtonsWrapper isColumn={screen(['lg', 'md', 'xs'])}>
												<UpfrontInstallmentButton
													isColumn={screen(['lg', 'md', 'xs'])}
													isSM={screen('sm')}
													onClick={() => {
														setInstallmentPeriod(undefined);
														setPlanType(PlanType.DEVICE_ONLY);
														if (setIsUpfront !== undefined) {
															setIsUpfront(true);
														}
													}}
													selected={planType === PlanType.DEVICE_ONLY && !installmentPeriod}
												>
													<ParagraphSBold>
														{translate('payment.summary.description.subtitle')}
													</ParagraphSBold>
												</UpfrontInstallmentButton>
												<UpfrontInstallmentButton
													isColumn={screen(['lg', 'md', 'xs'])}
													isSM={screen('sm')}
													onClick={() => {
														setInstallmentPeriod('12');
														if (setIsUpfront) {
															setIsUpfront(false);
														}
													}}
													selected={installmentPeriod === '12'}
													disabled={isHayyak}
												>
													<ParagraphSBold color={isLogged ? 'black87' : 'black38'}>
														{translate('payment.summary.installments.period', '12')}
													</ParagraphSBold>
												</UpfrontInstallmentButton>
												<UpfrontInstallmentButton
													isColumn={screen(['lg', 'md', 'xs'])}
													isSM={screen('sm')}
													onClick={() => {
														setInstallmentPeriod('24');
														if (setIsUpfront) {
															setIsUpfront(false);
														}
													}}
													selected={installmentPeriod === '24'}
													disabled={isHayyak}
												>
													<ParagraphSBold color={isLogged ? 'black87' : 'black38'}>
														{translate('payment.summary.installments.period', '24')}
													</ParagraphSBold>
												</UpfrontInstallmentButton>
											</InstallmentsButtonsWrapper>
										)}
										onFalse={() => (
											<InstallmentsButtonsWrapper isColumn={screen(['lg', 'md', 'xs'])}>
												<UpfrontInstallmentButton
													isColumn={screen(['lg', 'md', 'xs'])}
													isSM={screen('sm')}
													onClick={() => {
														setInstallmentPeriod(undefined);
														setPlanType(PlanType.DEVICE_ONLY);
														if (setIsUpfront !== undefined) {
															setIsUpfront(true);
														}
													}}
													selected={planType === PlanType.DEVICE_ONLY}
												>
													<ParagraphSBold>
														{translate('payment.summary.description.subtitle')}
													</ParagraphSBold>
												</UpfrontInstallmentButton>
												<UpfrontInstallmentButton
													isColumn={screen(['lg', 'md', 'xs'])}
													isSM={screen('sm')}
													disabled={
														!isLogged ||
														(isLogged &&
															!period12 &&
															planType === PlanType.WITH_PLAN &&
															!!selectedAccount?.accountNum &&
															!!selectedPlan &&
															!(!!showWarning && !userIsCorporate))
													}
													onClick={() => {
														if (
															!(
																!isLogged ||
																(isLogged &&
																	!period12 &&
																	planType === PlanType.WITH_PLAN &&
																	!!selectedAccount?.accountNum &&
																	!!selectedPlan &&
																	!(!!showWarning && !userIsCorporate))
															)
														) {
															setPlanType(PlanType.WITH_PLAN);
															if (!!setInstallmentPeriod) {
																setInstallmentPeriod('12');
																if (setIsUpfront) {
																	setIsUpfront(false);
																}
															}
														}
													}}
													selected={
														selectedFilters.installmentPeriod === '12' &&
														planType === PlanType.WITH_PLAN
													}
												>
													<ParagraphSBold color={isLogged ? 'black87' : 'black38'}>
														{translate('payment.summary.installments.period', '12')}
													</ParagraphSBold>
												</UpfrontInstallmentButton>
												<UpfrontInstallmentButton
													isColumn={screen(['lg', 'md', 'xs'])}
													isSM={screen('sm')}
													disabled={
														// to enable 24 months in Predictive Scoring for NG odf-10468/odf-10228
														!isLogged ||
														is24MonthsDisabled() ||
														(isLogged &&
															!period24 &&
															planType === PlanType.WITH_PLAN &&
															!!selectedAccount?.accountNum &&
															!!selectedPlan &&
															!(!!showWarning && !userIsCorporate))
													}
													onClick={() => {
														if (
															!(
																// to enable 24 months in Predictive Scoring for NG odf-10468/odf-10228
																(
																	!isLogged ||
																	is24MonthsDisabled() ||
																	(isLogged &&
																		!period24 &&
																		planType === PlanType.WITH_PLAN &&
																		!!selectedAccount?.accountNum &&
																		!!selectedPlan &&
																		!(!!showWarning && !userIsCorporate))
																)
															)
														) {
															setPlanType(PlanType.WITH_PLAN);
															if (!!setInstallmentPeriod) {
																setInstallmentPeriod('24');
																if (setIsUpfront) {
																	setIsUpfront(false);
																}
															}
														}
													}}
													selected={
														selectedFilters.installmentPeriod === '24' &&
														planType === PlanType.WITH_PLAN
													}
												>
													<ParagraphSBold color={isLogged ? 'black87' : 'black38'}>
														{translate('payment.summary.installments.period', '24')}
													</ParagraphSBold>
												</UpfrontInstallmentButton>
											</InstallmentsButtonsWrapper>
										)}
									/>
								)}
							/>
						</BusyIndicator>
					</Row>
					<Row>
						<ConditionalRender
							show={isBuyNowPayLaterFlow && !isUpfront}
							onTrue={() => (
								<OuterBanner>
									<BannerImg src={bnplBannerImageUrl} alt="Bnpl Banner" />
								</OuterBanner>
							)}
						/>
					</Row>

					<WithPlanContainer hasBottomMargin={true}>
						<ConditionalRender
							show={!!isB2b && planType === PlanType.WITH_PLAN}
							onTrue={() => (
								<ConditionalRender
									show={!!profileHasMultiAccount}
									onTrue={() => (
										<Row>
											<RegularDropdown
												dropdownHeight={DropdownHeight.SCROLL}
												inputFiltering={true}
												inputValue={inputFiltering}
												setInputValue={(value: string) => setInputFiltering(value)}
												items={dropdownItems}
												setSelectedItem={setSelectedDropdownItem}
												selectedItem={selectedDropdownItem}
												minSearchTextLength={3}
												maxDisplayedData={20}
												label={translate('plans.selected.number.dropdown')}
												labelType={LabelType.FLOATING}
												placeholder={selectedDropdownItem?.text}
												textFieldWidth="100%"
												dropdownWidth="100%"
												autoScroll={false}
												dropdownListWidth="calc(100% - 32px)"
											/>
										</Row>
									)}
									onFalse={() => (
										<Row>
											<SpaceBetweenContainer>
												<div>
													<CaptionBold color="black54">
														{translate('product.details.plan.your.number')}
													</CaptionBold>
													<ParagraphM color="black87">
														{selectedAccount?.accountNum}
													</ParagraphM>
												</div>
												<BillingTypeWrapper>
													<Value color="black38">
														{translate(
															`plans.selected.number.${selectedAccount?.billingType}.${selectedAccount?.accountType}`
														)}
													</Value>
												</BillingTypeWrapper>
											</SpaceBetweenContainer>
										</Row>
									)}
								/>
							)}
						/>
					</WithPlanContainer>
					<WithPlanContainer>
						<ConditionalRender
							show={planType === PlanType.WITH_PLAN || (isBuyNowPayLaterFlow && !!installmentPeriod)}
							onTrue={() => (
								<>
									<Row>
										<Divider marginBottom={12} marginTop={8} />
									</Row>
									<ConditionalRender
										show={!!profileHasMultiAccount && !isB2b}
										onTrue={() => (
											<Row>
												<RegularDropdown
													dropdownHeight={DropdownHeight.SCROLL}
													items={dropdownItems}
													setSelectedItem={setSelectedDropdownItem}
													selectedItem={selectedDropdownItem}
													label={translate('plans.selected.number.dropdown')}
													labelType={LabelType.FLOATING}
													placeholder={selectedDropdownItem?.text}
													dropdownWidth="100%"
													dropdownListWidth="calc(100% - 32px)"
												/>
											</Row>
										)}
										onFalse={() => (
											<ConditionalRender
												show={!!selectedAccount?.accountNum && !isB2b}
												onTrue={() => (
													<Row>
														<SpaceBetweenContainer>
															<div>
																<CaptionBold color="black54">
																	{translate('product.details.plan.your.number')}
																</CaptionBold>
																<ParagraphM color="black87">
																	{selectedAccount?.accountNum}
																</ParagraphM>
															</div>
															<BillingTypeWrapper>
																<Value color="black38">
																	{translate(
																		`plans.selected.number.${selectedAccount?.billingType}.${selectedAccount?.accountType}`
																	)}
																</Value>
															</BillingTypeWrapper>
														</SpaceBetweenContainer>
													</Row>
												)}
											/>
										)}
									/>
								</>
							)}
						/>
						<ConditionalRender
							show={!isBuyNowPayLaterFlow && !!userIsCorporate && !!commitmentPeriods?.length}
							onTrue={() => (
								<MarginContainer>
									<BusyIndicator listener={[GET_SMART_VOUCHER]} skeleton="commitions">
										<>
											<ConditionalRender
												show={!!userIsCorporate && !!commitmentPeriods?.length}
												onTrue={() => (
													<Row>
														<Title textTransform="uppercase" color="black54">
															{translate(
																'payment.summary.title.device.commitment-period'
															)}
														</Title>
													</Row>
												)}
											/>

											<Row>
												<ConditionalRender
													show={!!userIsCorporate && !!commitmentPeriods?.length}
													onTrue={() => (
														<>
															<MultipleSegmentSelector<string>
																segments={commitmentPeriods}
																size={ISegmentSize.BIG}
																selectedValue={selectedCommitmentPeriod}
																onChange={(value: string) => {
																	selectCommitmentPeriod(value);
																}}
															/>
														</>
													)}
												/>
											</Row>
										</>
									</BusyIndicator>
								</MarginContainer>
							)}
						/>
						<ConditionalRender
							show={
								planType === PlanType.WITH_PLAN &&
								isLogged &&
								!showWarning &&
								!userIsCorporate &&
								(!isB2b || !!isBaqatiPlan)
							}
							onTrue={() => (
								<ConditionalRender
									show={!!selectedDropdownItem || (!profileHasMultiAccount && !!selectedAccount)}
									onTrue={() => (
										<>
											<ConditionalRender
												show={!!properPlan || !!isHbbInstallmentsOffer}
												onTrue={() => (
													<PlanContainer marginTop={16}>
														<Row>
															<Subheading color="black54">
																{translate(
																	'product.details.plan.your.current.plan'
																).toUpperCase()}
															</Subheading>
														</Row>
														<Row>
															<PlanBarWrapper isUpgradeAvailable={upgradeNow}>
																<BusyIndicator
																	listener={[
																		GET_CUSTOMER_PRODUCTS,
																		CHECK_IF_CAN_GET_NEW_PLAN,
																		GET_RECOMMENDED_PLAN,
																		GET_HIGHER_PLAN,
																		GET_TELESALES_ACCOUNT_INFO_BI,
																	]}
																	skeleton="planBar"
																>
																	<>
																		<PlanBar
																			barType={getPlanBar()}
																			plan={{
																				name:
																					currentPlan?.commerceAttributes
																						?.shortDescription ||
																					currentPlan?.name,
																				validity: translate(
																					'plan.contract.duration',
																					String(
																						currentPlan?.commerceAttributes
																							?.duration ?? ''
																					)
																				),
																				price:
																					currentPlan?.commerceAttributes
																						?.price,
																				internet:
																					currentPlan?.commerceAttributes
																						?.planDetails?.includedData
																						?.itemLabel,
																				minutes:
																					currentPlan?.commerceAttributes
																						?.planDetails?.includedMinutes
																						?.itemLabel || '',
																				expireDate: currentPlan?.endDate || '',
																				promotion: {
																					promotion: false,
																				},
																				currentPlan: true,
																				vat:
																					Number(
																						currentPlan?.commerceAttributes
																							?.price || 0
																					) +
																					Number(
																						currentPlan?.commerceAttributes
																							?.vatValue || 0
																					),
																				vatPercent: Number(
																					currentPlan?.commerceAttributes
																						?.vatPercent || 0
																				),
																				hasCommitment,
																			}}
																		/>
																		<ConditionalRender
																			show={!!isHbbInstallmentsOffer}
																			onTrue={() => (
																				<MessageBar
																					iconSize={32}
																					message={translate(
																						'plans.is.hbb.installemnts.offer'
																					)}
																					icon="warning"
																					backgroundColor="grey100"
																					contentFill="black38"
																				/>
																			)}
																		/>
																	</>
																</BusyIndicator>
															</PlanBarWrapper>
														</Row>
													</PlanContainer>
												)}
											/>

											<ConditionalRender
												show={
													(!accessoryWithPlan &&
														!mobileDeviceWithPlan &&
														!isTabletWithHBBWFBBPlan &&
														!isAccessoryWithHBBWFBBPlan &&
														!isAlUfuq) ||
													(currentPlanIsHBBOrWFBB &&
														!isTabletWithHBBWFBBPlan &&
														!isAccessoryWithHBBWFBBPlan &&
														(plansToSubscribeValidated?.length ?? 0) > 1)
												}
												onTrue={() => (
													<PlanContainer marginTop={0}>
														<Row>
															<ConditionalRender
																show={upgradeNow || !!cantBeUpgradedPlanId}
																onTrue={() => (
																	<>
																		<ConditionalRender
																			show={!!recommendedPlan}
																			onTrue={() => (
																				<SpaceBetweenContainer>
																					<Subheading color="black54">
																						{translate(
																							selectedPlan
																								? 'product.details.plan.selected.plan'
																								: 'product.details.plan.recommended.plan'
																						).toUpperCase()}
																					</Subheading>
																					<ConditionalRender
																						show={
																							!isAlUfuq &&
																							!isHbbInstallmentsOffer
																						}
																						onTrue={() => (
																							<ConditionalRender
																								show={
																									!cantBeUpgradedPlanId
																								}
																								onTrue={() => (
																									<TertiaryMediumButtonAuto
																										uniqueId="product.details.plan.change"
																										listener={
																											VALIDATE_OFFER
																										}
																										onClick={
																											handlePlanChange
																										}
																									>
																										<ButtonM color="primary">
																											{translate(
																												'product.details.plan.change'
																											)}
																										</ButtonM>
																									</TertiaryMediumButtonAuto>
																								)}
																							/>
																						)}
																					/>
																				</SpaceBetweenContainer>
																			)}
																		/>
																		<PlanBarWrapper>
																			<BusyIndicator
																				listener={[
																					GET_RECOMMENDED_PLAN,
																					GET_PLANS,
																					GET_CUSTOMER_PRODUCTS,
																					GET_HIGHER_PLAN,
																					CHECK_IF_CAN_GET_NEW_PLAN,
																					CHECK_IF_CAN_GET_NEW_PLAN,
																					GET_ACCOUNT_INFO_BI,
																					GET_TELESALES_ACCOUNT_INFO_BI,
																				]}
																				skeleton="planBar"
																			>
																				<ConditionalRender
																					show={!!properPlan}
																					onTrue={() => (
																						<>
																							<PlanBar
																								barType={getSelectedPlanBar()}
																								plan={
																									properPlanExtracted
																								}
																							/>

																							<ConditionalRender
																								show={
																									!cantBeUpgradedPlanId
																								}
																								onTrue={() => (
																									<PlanBarSubtitle>
																										{translate(
																											'upgrade.plan.your.plan.will.be.upgraded',
																											properPlan?.shortDescription ||
																												''
																										)}
																									</PlanBarSubtitle>
																								)}
																							/>
																						</>
																					)}
																				/>
																			</BusyIndicator>
																		</PlanBarWrapper>
																	</>
																)}
																onFalse={() => (
																	<ConditionalRender
																		show={
																			!cantBeUpgradedPlanId &&
																			(!isB2b || !!isBaqatiPlan) &&
																			!!plansToSubscribeValidated &&
																			plansToSubscribeValidated?.length > 0
																		}
																		onTrue={() => (
																			<>
																				<Divider marginBottom={24} />
																				<Title
																					textTransform="uppercase"
																					color="black54"
																				>
																					{translate(
																						'product.details.plan-upgrade'
																					)}
																				</Title>
																				<UpgradeContainer>
																					<BusyIndicator
																						listener={[
																							GET_RECOMMENDED_PLAN,
																							GET_PLANS,
																							GET_CUSTOMER_PRODUCTS,
																							GET_HIGHER_PLAN,
																							CHECK_IF_CAN_GET_NEW_PLAN,
																							CHECK_IF_CAN_GET_NEW_PLAN,
																							GET_ACCOUNT_INFO_BI,
																							GET_TELESALES_ACCOUNT_INFO_BI,
																						]}
																					>
																						<UpgradePlan
																							label={
																								currentPlanIsHBBOrWFBB
																									? getTranslationUpgradePlanWfbb()
																									: getTranslationUpgradePlan()
																							}
																							firstButtonLabel={translate(
																								'upgrade.plan.button.upgrade.now'
																							)}
																							secondButtonLabel={translate(
																								'upgrade.plan.button.get.details'
																							)}
																							onFirstButtonClick={() => {
																								setUpgradeNow(
																									!upgradeNow
																								);
																							}}
																							onSecondButtonClick={
																								handlePlanChange
																							}
																							currentPlanIsHBB={
																								currentPlanIsHBBOrWFBB
																							}
																						/>
																					</BusyIndicator>
																				</UpgradeContainer>
																			</>
																		)}
																	/>
																)}
															/>
														</Row>
													</PlanContainer>
												)}
											/>

											<Row>
												<ConditionalRender
													show={
														!!isB2b &&
														!!recommendedPlan &&
														!!properPlan &&
														!!properPlan.duration &&
														!accessoryWithPlan &&
														!mobileDeviceWithPlan &&
														!isTabletWithHBBWFBBPlan &&
														!isAccessoryWithHBBWFBBPlan
													}
													onTrue={() => (
														<>
															<Divider marginBottom={24} marginTop={24} />
															<AdditionalInfoTitle textTransform="uppercase">
																{translate('plan.plan.commitment.expiration')}
															</AdditionalInfoTitle>
															<ExpirationWrapper>
																<ConditionalRender
																	show={hasCommitment}
																	onTrue={() => (
																		<>
																			<ExpireContainer>
																				<Caption color="black54">
																					{translate(
																						'product.details.plan.expires.on'
																					)}
																				</Caption>
																				<BodySBold>
																					{currentPlan?.endDate}
																				</BodySBold>
																			</ExpireContainer>
																			<IconWrapper>
																				<Icon
																					width={ICON_24}
																					height={ICON_24}
																					name="arrowSwipe"
																				/>
																			</IconWrapper>
																			<ExpireContainer>
																				<Caption color="support2">
																					{translate(
																						'product.details.plan.exp.date.after.renewal'
																					)}
																				</Caption>
																				<BodySBold>
																					{properPlan && properPlan?.duration
																						? moment()
																								.add(
																									properPlan?.duration,
																									'M'
																								)
																								.format('DD.MM.YYYY')
																						: ''}
																				</BodySBold>
																			</ExpireContainer>
																		</>
																	)}
																	onFalse={() => (
																		<ConditionalRender
																			show={!!properPlan && !!properPlan.duration}
																			onTrue={() => (
																				<ExpireContainer>
																					<Caption color="black54">
																						{translate(
																							'plan.no.commitment.new.contract',
																							String(
																								properPlan?.duration ??
																									'-'
																							)
																						)}
																					</Caption>
																				</ExpireContainer>
																			)}
																		/>
																	)}
																/>
															</ExpirationWrapper>
														</>
													)}
												/>
											</Row>
										</>
									)}
								/>
							)}
						/>
					</WithPlanContainer>
					<ConditionalRender
						show={
							(!!showWarning && !userIsCorporate) ||
							(!!userIsCorporate &&
								!commitmentPeriods?.length &&
								!!selectedAccount &&
								planType === PlanType.WITH_PLAN)
						}
						onTrue={() => (
							<BusyIndicator
								listener={[
									GET_RECOMMENDED_PLAN,
									GET_PLANS,
									GET_CUSTOMER_PRODUCTS,
									GET_ACCOUNT_FOR_LOGGED_USER,
									GET_HIGHER_PLAN,
									CHECK_IF_CAN_GET_NEW_PLAN,
									VALIDATE_CHANGED_INSTALLMENT_SKUS,
									GET_PRODUCT_DETAILS_BI,
									VALIDATE_OFFER,
									GET_ACCOUNT_INFO_BI,
									GET_TELESALES_ACCOUNT_INFO_BI,
									GET_SMART_VOUCHER,
									SELECT_ACCOUNT,
								]}
								skeleton="empty"
							>
								<Row>
									<PlanEditWarning>
										<Icon name="warning" width={ICON_64} height={ICON_64} fill="primary" />
										<PlanEditWarningTitle>
											{!!userIsCorporate && !commitmentPeriods?.length
												? translate(smartVoucherErrorInformation)
												: showTwoDeviceAccessoriesWarning
												? translate(
														'product.details.plan.edit.warning.two-device-accessories-installment'
												  )
												: showTwoMobileDeviceWarning
												? translate(
														'product.details.plan.edit.warning.two-mobile-device-installment'
												  )
												: blockUpgradeTo5GAnd4G5G
												? translate('product.details.plan.edit.warning.block.5g')
												: showTwoMonthWarning
												? translate('product.details.plan.edit.warning.two-month-installment')
												: userHaveLegacyPlan && !isHayyak
												? translate('product.details.plan.edit.warning.legacy.plan')
												: wrongDeviceChoosen
												? translate('product.details.plan.edit.warning.wrong.device')
												: isInstallmentAmountError
												? getWarningMessageForInstallmentAmount(errorCodeForInstallmentAmount)
												: translate('product.details.plan.edit.warning')}
										</PlanEditWarningTitle>
										<ParagraphM>
											{translate('product.details.plan.edit.warning.buy.device.only')}
											<PlanEditWarningDescriptionUnderline onClick={handleDeviceOnly}>
												{translate('product.details.plan.edit.warning.device.only.underline')}
											</PlanEditWarningDescriptionUnderline>
											{translate(
												!!profileHasMultiAccount
													? 'product.details.plan.edit.warning.device.only.other.number'
													: 'product.details.plan.edit.warning.device.only',
												isB2b2c || isB2b ? CUSTOMER_B2B2C_CARE_PHONE : CUSTOMER_CARE_PHONE
											)}
										</ParagraphM>
									</PlanEditWarning>
								</Row>
							</BusyIndicator>
						)}
					/>
				</CardWrapper>
			)}
		/>
	);
};

export default Plan;
